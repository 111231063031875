
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column";
export default function ScheduledVehiclesDetails(props: any) {

  return (
    <>
      <div className="row">
        <div className="col-12  history-table p-3 rounded-bottom">
          <DataTable
            value={props.scheduledVehicleResponse}
            paginator
            paginatorTemplate="PrevPageLink PageLinks NextPageLink"
            rows={8}
            selectionMode="single"
          >
            <Column header="VIN" field={props.fromHistoryPage == true ? "VIN":"vin"} sortable></Column>
            <Column header="Make" field={props.fromHistoryPage == true ? "Make":"make"} sortable ></Column>
            <Column header="Year" field={props.fromHistoryPage == true ? "Year":"year"}  sortable></Column>
          </DataTable>
        </div>
      </div>
    </>
  )
}