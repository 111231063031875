import { useEffect, useState, useRef } from "react";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import moment from "moment";
import CommonValues from "../../common/utils";
import axios from "axios";
import { Toast } from "primereact/toast";
import { Navigate, useNavigate } from "react-router-dom";
import AtkButton from "../../AtlaskitControls/AtkButton";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import CapitalResponse from "./CapitalResponse";
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import { useLocation } from "react-router-dom";
import IpfsResponse from "./IpfsResponse";
import GotoPFResponse from "./GotoPFResponse";
import Peoples from "./Peoples";
import FirstInsuranceFunding from "./FirstInsuranceFunding";
import UsPremiumFinance from "./UsPremiumFinance";
import AtkButtonIconBefore from "../../AtlaskitControls/AtkButtonIconBefore";

export default function QuickQuoteConfirmation(props: any) {
  const [term, setTerm] = useState<any>([""]);
  const [effectiveDate, setEffectiveDate] = useState<any>([""]);
  const [policyNumber, setPolicyNumber] = useState([""]);
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [addLine1, setAddLine1] = useState("");
  const [addLine2, setAddLine2] = useState("");
  const [businessProfile, setBusinessProfile] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessPhoneNumber, setBusinessPhoneNumber] = useState("");
  const [carrierSearch, setCarrierSearch] = useState<any>([]);
  const [carrierAddress, setCarrierAddress] = useState([""]);
  const [carrierCity, setCarrierCity] = useState([""]);
  const [carrierState, setCarrierState] = useState([""]);
  const [carrierZip, setCarrierZip] = useState([""]);
  const [carrierCountry, setCarrierCountry] = useState(["USA"]);
  const [gaName, setGaName] = useState([""]);
  const [gaAddress, setGaAddress] = useState([""]);
  const [gaCity, setGaCity] = useState([""]);
  const [gaState, setGaState] = useState([""]);
  const [gaZip, setGaZip] = useState([""]);
  const [gaCountry, setGaCountry] = useState([""]);
  const [brokerFee, setBrokerFee] = useState<any>([]);
  const [currentPremiumsArray, setCurrentPremiumsArray] = useState<any>([""]);
  const [taxes, setTaxes] = useState<any>([]);
  const [fees, setFees] = useState<any>([]);
  const [minimumEarned, setminimumEarned] = useState<any>([]);
  const [sstate, setSstate] = useState("");
  const toast: any = useRef("");
  const navigate = useNavigate();
  const [carrierName, setCarrierName] = useState([""]);
  const [carrierValue, setCarrierValue] = useState<any>({});
  const [dataMappingLoading, setDataMappingLoading] = useState<any>([]);
  const [coverageCode, setCoverageCode] = useState<any>([]);
  const [coverageCodeError, setCoverageCodeError] = useState([""]);
  const [invalidPolicyError, setInvalidPolicyError] = useState([""]);
  const [carrier, setCarrier] = useState<any>([]);
  const [inputValue, setInputValue] = useState<any>({});
  const [selectedSubPolicy, setSelectedSubPolicy] = useState(0);
  const [carrierList, setCarrierList] = useState([]);
  const [carrierCodeLoading, setCarrierCodeLoading] = useState(false);
  const [nowCertsClientsByIdLoading, setNowCertsClientsByIdLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [clientId, setClientId] = useState("");
  const [nCInsuredId, setNCInsuredId] = useState("");
  const [agentName, setAgentName] = useState("");
  const [agentPhone, setAgentPhone] = useState("");
  const [agentAddress, setAgentAddress] = useState("");
  const [agentCity, setAgentCity] = useState("");
  const [agentState, setAgentState] = useState("");
  const [agentZip, setAgentZip] = useState("");
  const [agentCountry, setAgentCountry] = useState("");
  const [nCPolicyId, setNCPolicyId] = useState("");
  const [gaSearchCode, setGaSearchCode] = useState([""]);
  const [subPolicyLobs, setSubPolicyLobs] = useState<any>([]);
  const [particularPremiumSettings, setParticularPremiumSettings] = useState<any>([]);
  const [capitalShowResponse, setCapitalShowResponse] = useState(false);
  const [iPFSShowResponse, setIPFSShowResponse] = useState(false);
  const [peoplesShowResponse, setPeoplesShowResponse] = useState(false);
  const [firstInsuranceFundingShowResponse, setFirstInsuranceFundingShowResponse] = useState(false);
  const [usPfShowResponse, setUsPfShowResponse] = useState(false);
  const [gotoShowResponse, setGotoShowResponse] = useState(false);
  const [capitalLogoUrl, setCapitalLogoUrl] = useState("");
  const [capitalTarget, setCapitalTarget] = useState("CAPITALNC");
  const [enableQuickQuote, setEnableQuickQuote] = useState(false);
  const [showCarrierDropDown, setShowCarrierDropDown] = useState(false);
  const [showGeneralAgencyDropDown, setShowGeneralAgencyDropDown] = useState(true);
  const [amsAgencySettingLoading, setAmsAgencySettingLoading] = useState(false);
  const [policyIndex, setPolicyIndex] = useState("");
  const [generalAgencyList, setGeneralAgencyList] = useState([]);
  const [generalAgencySearch, setGeneralAgencySearch] = useState([""]);
  const location = useLocation();
  const momentumPolicyId = location.state?.momentumPolicyId || "";
  const [carrierNameError, setCarrierNameError] = useState([""]);
  const [usPfLogoUrl, setUsPfLogoUrl] = useState("");
  const [iPFSLogoUrl, setIpfsLogoUrl] = useState("");
  const [peoplesLogoUrl, setPeoplesLogoUrl] = useState("");
  const [firstInsuranceFundingLogoUrl, setFirstInsuranceFundingLogoUrl] = useState("");
  const [gotoLogoUrl, setGotoLogoUrl] = useState("");
  const [hideShowAllQuoteBtn, setHideShowAllQuoteBtn] = useState(false);

  useEffect(() => {
    getPremiumFinanceSettingsById();
    getEnableQuickQuoteResponseById();
    // if (localStorage.getItem("IsIndicative") == null || localStorage.getItem("IsIndicative") == "false") {
    if (CommonValues.GetTenantId() == process.env.REACT_APP_NowCerts_Tenant_Id) {
      let policyIdsString = localStorage.getItem("policyIds");

      getAmsAgencySettings();
      setNCPolicyId(props.selectedPolicyId);
      let lobArray: any = [];
      let newPolicyNum: any = [];
      let newEffDt: any = [];
      let newPrem: any = [];
      let newCoverageCodes: any = [];
      let newCarrierValues: any = [];
      let newCarrierName: any = [];
      let newCarrierAddress: any = [];
      let newCarrierCity: any = [];
      let newCarrierState: any = [];
      let newCarrierZip: any = [];
      let newGa: any = [];
      let newTerm: any = [];
      let newTaxes: any = [];
      let newFees: any = [];
      if (momentumPolicyId != null && momentumPolicyId != "") {
        getPolicyDetails(
          momentumPolicyId,
          0,
          lobArray,
          newPolicyNum,
          newEffDt,
          newPrem,
          newCoverageCodes,
          newCarrierValues,
          newCarrierName,
          newCarrierAddress,
          newCarrierCity,
          newCarrierState,
          newCarrierZip,
          newTerm,
          newTaxes,
          newFees
        );
      } else if (policyIdsString !== null && policyIdsString.trim() !== "[]") {
        try {
          const policyIds = JSON.parse(policyIdsString);
          const processPolicyIds = async (index: number) => {
            if (index < policyIds.length) {
              const policy = policyIds[index];
              await getPolicyDetails(
                policy.id,
                policy.index,
                lobArray,
                newPolicyNum,
                newEffDt,
                newPrem,
                newCoverageCodes,
                newCarrierValues,
                newCarrierName,
                newCarrierAddress,
                newCarrierCity,
                newCarrierState,
                newCarrierZip,
                newTerm,
                newTaxes,
                newFees
              );
              await processPolicyIds(index + 1);
            } else {
            }
          };
          setNCPolicyId(policyIds[0].id);
          let storedData = localStorage.getItem("client");
          let clianttlist = storedData ? JSON.parse(storedData) : null;
          getNowCertsClientsById(clianttlist.clientId);
          setClientId(clianttlist.clientId);
          setNCInsuredId(clianttlist.clientId);
          processPolicyIds(0);
          policyIds.forEach((item: any, index: any) => {
            newCarrierName.push(item.ncCarrierName);
            newGa.push(item.ncGaName);
            getCoverageCodeMapping(item.ncCoverageCode, "code", index, newCoverageCodes, [], []);
          });
          // getCarrierCodeMappingForHawksoftBundle(newCarrierName, []);
          // getGeneralAgencyMappingForHawksoftBundle(newGa, []);
        } catch (error) {
          console.error("Error parsing policyIds:", error);
        }
      } else {
        getPolicyDetails(
          props.selectedPolicyId,
          0,
          lobArray,
          newPolicyNum,
          newEffDt,
          newPrem,
          newCoverageCodes,
          newCarrierValues,
          newCarrierName,
          newCarrierAddress,
          newCarrierCity,
          newCarrierState,
          newCarrierZip,
          newTerm,
          newTaxes,
          newFees
        );
      }
    }
  }, []);
  useEffect(() => {
    if (carrierSearch.length == 0 || carrierSearch[selectedSubPolicy] == "") return;
    let newCarrierValues: any = carrierValue != null && carrierValue != undefined ? carrierValue : [];
    let newCarrierName: any = carrierName != null && carrierName != undefined ? carrierName : [];
    let newCarrierAddress: any = [];
    let newCarrierCity: any = [];
    let newCarrierState: any = [];
    let newCarrierZip: any = [];

    if (localStorage.getItem("IsIndicative") == "true") {
      getCarrierCodeMappingForCPF(
        "",
        carrierSearch[selectedSubPolicy],
        0,
        1,
        [],
        newCarrierValues,
        newCarrierName,
        newCarrierAddress,
        newCarrierCity,
        newCarrierState,
        newCarrierZip,
        ""
      );
    }

    const searchTimeout = setTimeout(() => {
      getCarrierCodeMappingForCPF(
        "",
        carrierSearch[selectedSubPolicy],
        0,
        1,
        [],
        newCarrierValues,
        newCarrierName,
        newCarrierAddress,
        newCarrierCity,
        newCarrierState,
        newCarrierZip,
        ""
      );
    }, 10);

    return () => clearTimeout(searchTimeout);
  }, [carrierSearch]);

  const getPolicyDetails = async (
    policyId: any,
    indexx: any,
    lobArray: any,
    newPolicyNum: any,
    newEffDt: any,
    newPrem: any,
    newCoverageCodes: any,
    newCarrierValues: any,
    newCarrierName: any,
    newCarrierAddress: any,
    newCarrierCity: any,
    newCarrierState: any,
    newCarrierZip: any,
    newTerm: any,
    newTaxes: any,
    newFees: any
  ) => {
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    var token = CommonValues.GetToken();

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/policydetails?databaseId=${policyId}&nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      setNowCertsClientsByIdLoading(true);
      const response = await axios(config);
      let policyInfo = response.data;
      if (policyInfo != null && policyInfo.length > 0) {
        newPrem.push(parseFloat(response.data[0].totalPremium).toFixed(2));
        setCurrentPremiumsArray(newPrem);
        //  upDateTotalCurrentPremiuim(newPrem, true);
        let invalidPolicyErrorLoop: any = [];
        let policyIdsString = localStorage.getItem("policyIds");
        if (policyIdsString !== null && policyIdsString.trim() !== "[]") {
          lobArray.push({ code: policyInfo[0].lineOfBusinesses[0].lineOfBusinessName });
          newPolicyNum[lobArray.length - 1] = response.data[0].number;
          newEffDt[lobArray.length - 1] = moment.utc(response.data[0].effectiveDate).format("YYYY-MM-DD");
          // newTerm[lobArray.length - 1] =  policyInfo[0].policyTerm;
          if (policyInfo[0].policyTerm !== "" && policyInfo[0].policyTerm !== null) {
            newTerm[lobArray.length - 1] = policyInfo[0].policyTerm;
          } else {
            newTerm[lobArray.length - 1] = "12";
          }

          getPolicyEndorsementDetailList(policyInfo[0].lineOfBusinesses[0].policyDatabaseId, lobArray.length - 1, newTaxes, newFees);
          setTerm(newTerm);
          setSubPolicyLobs(lobArray);
          setPolicyNumber(newPolicyNum);
          setEffectiveDate(newEffDt);
        } else {
          if (policyInfo[0].lineOfBusinesses != null && policyInfo[0].lineOfBusinesses.length > 0) {
            if (policyInfo[0].lineOfBusinesses.length > 1) {
              lobArray.push({ code: "Commercial Package" });
              newPolicyNum[lobArray.length - 1] = response.data[0].number;
              newEffDt[lobArray.length - 1] = moment.utc(response.data[0].effectiveDate).format("YYYY-MM-DD");
              // newTerm[lobArray.length - 1] = policyInfo[0].policyTerm;
              if (policyInfo[0].policyTerm !== "" && policyInfo[0].policyTerm !== null) {
                newTerm[lobArray.length - 1] = policyInfo[0].policyTerm;
              } else {
                newTerm[lobArray.length - 1] = "12";
              }
              setTerm(newTerm);
              GetDataMappingForPF(
                "Commercial Package",
                lobArray.length - 1,
                newCoverageCodes,
                [],
                [], //for coverage code mapping
                policyInfo[0].carrierName,
                policyInfo[0].carrierFinanceSearchCoCode,
                "",
                lobArray.length,
                [],
                newCarrierValues,
                newCarrierName,
                newCarrierAddress,
                newCarrierCity,
                newCarrierState,
                newCarrierZip
              );
              getPolicyEndorsementDetailList(policyInfo[0].lineOfBusinesses[0].policyDatabaseId, lobArray.length - 1, newTaxes, newFees);
            } else {
              policyInfo[0].lineOfBusinesses.forEach((item: any, index: any) => {
                if (policyInfo[0].policyTerm !== "" && policyInfo[0].policyTerm !== null) {
                  newTerm[0] = policyInfo[0].policyTerm;
                } else {
                  newTerm[0] = "12";
                }
                lobArray.push({ code: item.lineOfBusinessName });
                newPolicyNum[lobArray.length - 1] = response.data[0].number;
                newEffDt[lobArray.length - 1] = moment.utc(response.data[0].effectiveDate).format("YYYY-MM-DD");
                // newTerm[lobArray.length - 1] = policyInfo[0].policyTerm;

                GetDataMappingForPF(
                  item.lineOfBusinessName,
                  lobArray.length - 1,
                  newCoverageCodes,
                  [],
                  [],
                  policyInfo[0].carrierName,
                  policyInfo[0].carrierFinanceSearchCoCode,
                  "",
                  lobArray.length,
                  [],
                  newCarrierValues,
                  newCarrierName,
                  newCarrierAddress,
                  newCarrierCity,
                  newCarrierState,
                  newCarrierZip
                );
                getPolicyEndorsementDetailList(item.policyDatabaseId, lobArray.length - 1, newTaxes, newFees);
              });
            }
            setTerm(newTerm);
            setSubPolicyLobs(lobArray);
            setPolicyNumber(newPolicyNum);
            setEffectiveDate(newEffDt);
          }

          if (policyInfo[0].insuredDatabaseId != null || policyInfo[0].insuredDatabaseId != "") {
            let insuredDatabaseId = policyInfo[0].insuredDatabaseId;
            setClientId(insuredDatabaseId);
            getNowCertsClientsById(insuredDatabaseId);
            setNCInsuredId(insuredDatabaseId);
          } else {
            setNowCertsClientsByIdLoading(false);
          }
        }
        // if (momentumPolicyId != null && momentumPolicyId != "" && enableQuickQuote === true) {
        //   setCapitalTarget("CAPITALNC");
        //   setTimeout(() => {
        //     getCapitalQuoteFromPopup();
        //   }, 2500);
        // } else {
        //   setCapitalTarget("CAPITAL");
        // }
      }
    } catch (error: any) {
      setNowCertsClientsByIdLoading(false);

      if (error.response != null) {
        if (error.response.status == 401) {
          CommonValues.Logout(navigate);
        } else {
          if (props.momentumPolicyId != null && props.momentumPolicyId != "") {
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while loading the policy details",
              life: 3000,
            });
          }
        }
      }
    }
  };

  const getNowCertsClientsById = (insuredDatabaseId: string) => {
    // setNowCertsClientsByIdLoading(true);
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    var data = {
      insuredDatabaseId: insuredDatabaseId,
    };
    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/customerlistbyId?insuredDatabaseId=${insuredDatabaseId}&nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        setNowCertsClientsByIdLoading(false);

        let insuredInfo = response.data;
        if (insuredInfo != null && insuredInfo.length > 0) {
          setBusinessProfile(insuredInfo[0].commercialName);
          setBusinessEmail(insuredInfo[0].eMail);
          setBusinessPhoneNumber(insuredInfo[0].phone);
          setAddLine1(insuredInfo[0].addressLine1);
          setAddLine2(insuredInfo[0].addressLine2);
          setCity(insuredInfo[0].city);
          setSstate(insuredInfo[0].stateNameOrAbbreviation);
          setZipCode(insuredInfo[0].zipCode);
          setClientId(insuredInfo[0].databaseId);
          setFirstName(insuredInfo[0].commercialName);
          setClientId(insuredInfo[0].databaseId);

          let clianttlist = {
            clientName: insuredInfo[0].commercialName,
            clientId: insuredInfo[0].databaseId,
          };
          props.onClientNameChange(insuredInfo[0].commercialName);
          localStorage.setItem("client", JSON.stringify(clianttlist));
        }
      })
      .catch((error: any) => {
        setNowCertsClientsByIdLoading(false);

        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
          }
        }
      });
  };
  const getAmsAgencySettings = () => {
    setAmsAgencySettingLoading(true);
    const token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/amsagencysettings`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setAgentName(response.data.agencyName);
        setAgentAddress(response.data.address1);
        setAgentCity(response.data.city);
        setAgentState(response.data.state);
        setAgentZip(response.data.zip);
        setAgentPhone(response.data.phone);
        if (response.data.country != null && response.data.country != "") {
          setCountry(response.data.country);
        } else {
          setCountry("USA");
        }
        setAmsAgencySettingLoading(false);
      })
      .catch((error: any) => {
        setAmsAgencySettingLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while loading the AMS Agency settings",
              life: 3000,
            });
          }
        }
      });
  };
  const getCoverageCodeMapping = (code: any, matchFlag: any, index: any, newValue: any, coverageCodeErrorLoop: any, invalidPolicyErrorLoop: any) => {
    var token = CommonValues.GetToken();
    matchFlag = code != null && code != undefined ? (code.includes(" ") ? "desc" : "code") : "code";

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/PremiumFinance/coveragecodesbyhscode?HsCode=${code}&MatchFlag=${matchFlag}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        newValue[index] = response.data;
        if (response.data == null || response.data == "") {
          coverageCodeErrorLoop[index] = "Please select coverage code";
          invalidPolicyErrorLoop[index] = "true";
        }
        setCoverageCode(newValue);
        setInputValue(newValue);
        setInvalidPolicyError(invalidPolicyErrorLoop);
        setCoverageCodeError(coverageCodeErrorLoop);
      })
      .catch((error: any) => {
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while loading  the coverage code details",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while loading  the coverage code details",
            life: 3000,
          });
        }
      });
  };
  const getPolicyEndorsementDetailList = (policyId: any, index: number, newTaxes: any, newFees: any) => {
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    const config = {
      method: "GET",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/getpolicyendorsementdetailslist?policyId=${policyId}&ncToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((response) => {
        newFees[index] = parseFloat(response.data[0]).toFixed(2);
        newTaxes[index] = parseFloat(response.data[1]).toFixed(2);
        setTaxes(newTaxes);
        setFees(newFees);
      })
      .catch((error) => {
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while getting policyendorsementdetaillist ",
              life: 3000,
            });
          }
        }
      });
  };
  const GetDataMappingForPF = (
    coveragecode: any,
    index: any,
    newValue: any,
    coverageCodeErrorLoop: any,
    invalidPolicyErrorLoop: any,
    carrierName: any,
    carrierSearchCode: any,
    search: any,
    subPolicyCount: any,
    [],
    newCarrierValues: any,
    newCarrierName: any,
    newCarrierAddress: any,
    newCarrierCity: any,
    newCarrierState: any,
    newCarrierZip: any
  ) => {
    let newLoadingValue: any = [];
    newLoadingValue[index] = true;
    setDataMappingLoading(newLoadingValue);
    var token = CommonValues.GetToken();
    var data = JSON.stringify({
      HsCode: coveragecode,
      MatchFlag: coveragecode.includes(" ") ? "desc" : "code",
      hscarrier: carrierName,
      generalAgency: "",
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/PremiumFinance/datamapping`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        setTimeout(() => {
          newLoadingValue[index] = false;
          setDataMappingLoading(newLoadingValue);
        }, 500);

        newValue[index] = response.data.coverageCodeMapping;
        if (response.data == null || response.data == "") {
          coverageCodeErrorLoop[index] = "Please select coverage code";
          invalidPolicyErrorLoop[index] = "true";
        }
        setCoverageCode(newValue);

        setInputValue(newValue);
        setInvalidPolicyError(invalidPolicyErrorLoop);
        setCoverageCodeError(coverageCodeErrorLoop);

        // if (search == "") {
        //   if (response.data.carrierCodeMappings != null) {
        //     if (subPolicyCount === index + 1) {
        //       for (let i = 0; i < subPolicyCount; i++) {
        //         newCarrierName[i] = response.data.carrierCodeMappings.description;
        //         setCarrierName(newCarrierName);
        //         newCarrierValues[i] = response.data.carrierCodeMappings;
        //         setCarrier(newCarrierValues);
        //         setCarrierValue(newCarrierValues);

        //         newCarrierAddress[i] = response.data.carrierCodeMappings.address1;
        //         setCarrierAddress(newCarrierAddress);

        //         newCarrierCity[i] = response.data.carrierCodeMappings.city;
        //         setCarrierCity(newCarrierCity);

        //         newCarrierState[i] = response.data.carrierCodeMappings.state;
        //         setCarrierState(newCarrierState);

        //         newCarrierZip[i] = response.data.carrierCodeMappings.zipCode;
        //         setCarrierZip(newCarrierZip);
        //       }
        //     }
        //   } else {
        //     invalidPolicyErrorLoop[index] = "";
        //     let newCarrierCountry = [];
        //     let newcarrierSearch = [];

        //     if (subPolicyCount === index + 1) {
        //       for (let i = 0; i < subPolicyCount; i++) {
        //         newCarrierCountry[i] = "USA";
        //         setCarrierCountry(newCarrierCountry);

        //         newCarrierName[i] = carrierName;
        //         setCarrierName(newCarrierName);
        //         newCarrierValues[i] = { description: carrierName };
        //         setCarrier(newCarrierValues);
        //         setCarrierValue(newCarrierValues);
        //       }
        //     }
        //   }
        // }

        getCarrierCodeMappingForCPF(
          carrierSearchCode,
          search,
          0,
          1,
          [],
          newCarrierValues,
          newCarrierName,
          newCarrierAddress,
          newCarrierCity,
          newCarrierState,
          newCarrierZip,
          response.data.carrierCodeMappings
        );

      })
      .catch((error: any) => {
        console.log(error);
        setTimeout(() => {
          newLoadingValue[index] = false;
          setDataMappingLoading(newLoadingValue);
        }, 500);
      });
  };
  const getPremiumFinanceSettingsById = () => {
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/pfcompanies`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        response.data.forEach((item: any) => {
          if (item.pfServiceId == 2) {
            setCapitalLogoUrl(item.logoUrl);
          }
          else {
            setCapitalLogoUrl("https://d1u42a3y69tca1.cloudfront.net/companydata/765/logo/CapitalPremiunFinanceLogo.jpg");
          }
        });
        setParticularPremiumSettings(response.data);
      })
      .catch((error: any) => {
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while loading the Premium finance Setting details by id",
              life: 3000,
            });
          }
        }
      });
  };
  const getEnableQuickQuoteResponseById = () => {
    // setEnableQuickQuoteLoading(true);

    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/enablequickquote`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setEnableQuickQuote(response.data);
        // setShowPolicyDetails(true);
        // setEnableQuickQuoteLoading(false);
      })
      .catch((error: any) => {
        // setEnableQuickQuoteLoading(false);

        // setShowPolicyDetails(true);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while loading the preferred vendor list by id" });
          }
        }
      });
  };
  const checkValidation = () => {
    let formIsValid = true;
    const newCarrierNameError = [];

    let policyIdsString = localStorage.getItem("policyIds");
    if ((carrierName == null || carrierName.length == 0) && policyIdsString == null) {
      formIsValid = false;
      for (let i = 0; i < subPolicyLobs.length; i++) {
        newCarrierNameError[i] = "Please select carrier ";
      }
      setCarrierNameError(newCarrierNameError);
    } else {
      for (let i = 0; i < subPolicyLobs.length; i++) {
        if (carrier[i] == undefined || carrier[i] == null || carrier[i] == "" || carrier[i].description == null || carrier[i].description == "") {
          newCarrierNameError[i] = "Please select carrier";
          formIsValid = false;
        } else {
          newCarrierNameError[i] = "";
        }
      }
      setCarrierNameError(newCarrierNameError);
    }
    return formIsValid;
  };
  const onSubmitClick = () => {
    if (checkValidation()) {
      setCapitalShowResponse(true);
    }
  };

  const onGeneralAgencySearchChange = (e: any) => {
    const newValues = [...gaName];
    newValues[selectedSubPolicy] = e.target.value;

    setGaName(newValues);
    getGeneralAgencyMapping("", newValues[selectedSubPolicy], 0, 1, []);
  };
  const onCarrierNameChange = (e: any) => {
    let newcarrierSearch = [];
    newcarrierSearch[selectedSubPolicy] = e.target.value;
    setCarrierSearch(newcarrierSearch);
  };
  const onCarrierEditIconClick = () => {
    setShowCarrierDropDown(!showCarrierDropDown);
  };
  const onGeneralAgencyEditIconClick = () => {
    setShowGeneralAgencyDropDown(!showGeneralAgencyDropDown);
  };
  const onCarrierselect = (item: any, e: any) => {
    setCarrierList([]);
    setCarrierSearch([]);
    if (item) {
      let newCarrierName = carrierName == null ? [] : [...carrierName];
      newCarrierName[selectedSubPolicy] = item.description;
      setCarrierName(newCarrierName);

      const newValues = [...carrier];
      newValues[selectedSubPolicy] = item;
      setCarrier(newValues);
      setCarrierValue(newValues);

      let newCarrierAddress = [...carrierAddress];
      newCarrierAddress[selectedSubPolicy] = item.address1 == undefined ? "" : item.address1;
      setCarrierAddress(newCarrierAddress);

      let newCarrierCity = [...carrierCity];
      newCarrierCity[selectedSubPolicy] = item.city == undefined ? "" : item.city;
      setCarrierCity(newCarrierCity);

      let newCarrierState = [...carrierState];
      newCarrierState[selectedSubPolicy] = item.state == undefined ? "" : item.state;
      setCarrierState(newCarrierState);

      let newCarrierZip = [...carrierZip];
      newCarrierZip[selectedSubPolicy] = item.zipCode == undefined ? "" : item.zipCode;
      setCarrierZip(newCarrierZip);
    }
    setShowCarrierDropDown(false);

    e.preventDefault();
  };
  const onGeneralAgencySelect = (item: any, e: any) => {
    setGeneralAgencySearch([]);
    const newValues = [...gaName];
    newValues[selectedSubPolicy] = item.description;
    setGaName(newValues);

    let newGaAddress = [...gaAddress];
    newGaAddress[selectedSubPolicy] = item.address1;
    setGaAddress(newGaAddress);

    let newGaCity = [...gaCity];
    newGaCity[selectedSubPolicy] = item.city;
    setGaCity(newGaCity);

    let newGaState = [...gaState];
    newGaState[selectedSubPolicy] = item.state;
    setGaState(newGaState);

    let newGaZip = [...gaZip];
    newGaZip[selectedSubPolicy] = item.zipCode;
    setGaZip(newGaZip);

    let newGaCountry = [...gaCountry];
    newGaCountry[selectedSubPolicy] = item.country;
    setGaCountry(newGaCountry);

    let newGaSearchCode = [...gaSearchCode];
    newGaSearchCode[selectedSubPolicy] = item.searchCode;
    setGaSearchCode(newGaSearchCode);

    setGeneralAgencyList([]);
    setShowGeneralAgencyDropDown(false);

    e.preventDefault();
  };
  const getCarrierCodeMappingForCPF = (
    carrierName: any,
    search: any,
    index: any,
    subPolicyCount: any,
    invalidPolicyErrorLoop: any,
    newCarrierValues: any,
    newCarrierName: any,
    newCarrierAddress: any,
    newCarrierCity: any,
    newCarrierState: any,
    newCarrierZip: any,
    carrierResponseByName: any
  ) => {
    setCarrierCodeLoading(true);
    let carrierData: any = [];

    var token = CommonValues.GetToken();
    var data = {
      search: search,
      hscarrier: carrierName,
    };
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/PremiumFinance/cpfcarriercodes`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then((response: any) => {
        setCarrierCodeLoading(false);

        if (search == "") {
          if (response.data.length > 0) {
            if (subPolicyCount === index + 1) {
              for (let i = 0; i < subPolicyCount; i++) {
                newCarrierName[i] = response.data[0].description;
                setCarrierName(newCarrierName);
                newCarrierValues[i] = response.data[0];
                setCarrier(newCarrierValues);
                setCarrierValue(newCarrierValues);

                newCarrierAddress[i] = response.data[0].address1;
                setCarrierAddress(newCarrierAddress);

                newCarrierCity[i] = response.data[0].city;
                setCarrierCity(newCarrierCity);

                newCarrierState[i] = response.data[0].state;
                setCarrierState(newCarrierState);

                newCarrierZip[i] = response.data[0].zipCode;
                setCarrierZip(newCarrierZip);
              }
            }
          }
          else {
            if (carrierResponseByName != null) {
              newCarrierName[index] = carrierResponseByName.description;
              setCarrierName(newCarrierName);
              newCarrierValues[index] = carrierResponseByName;
              setCarrier(newCarrierValues);
              setCarrierValue(newCarrierValues);

              newCarrierAddress[index] = carrierResponseByName.address1;
              setCarrierAddress(newCarrierAddress);

              newCarrierCity[index] = carrierResponseByName.city;
              setCarrierCity(newCarrierCity);

              newCarrierState[index] = carrierResponseByName.state;
              setCarrierState(newCarrierState);

              newCarrierZip[index] = carrierResponseByName.zipCode;
              setCarrierZip(newCarrierZip);
            }
          }
        } else {
          setCarrierList(response.data);
          invalidPolicyErrorLoop[index] = "";
        }
      })
      .catch((error: any) => {
        setCarrierCodeLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while loading the carrier details",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while loading the carrier details",
            life: 3000,
          });
        }
      });
  };
  const getGeneralAgencyMapping = (generalAgencyName: any, search: any, index: any, subPolicyCount: any, invalidPolicyErrorLoop: any) => {
    var token = CommonValues.GetToken();
    var data = {
      search: search,
      generalagency: generalAgencyName,
    };
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/PremiumFinance/generalagencycodes`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        if (search == "") {
          if (response.data.length > 0) {
            let newGaNames = [...gaName];
            let newGaAddress = [];
            let newGaCity = [];
            let newGaState = [];
            let newGaZip = [];
            let newGaCountry = [];
            let newGaSearchCode = [];
            if (subPolicyCount === index + 1) {
              for (let i = 0; i < subPolicyCount; i++) {
                newGaNames[i] = response.data[0].description;
                setGaName(newGaNames);

                newGaAddress[i] = response.data[0].address1;
                setGaAddress(newGaAddress);

                newGaCity[i] = response.data[0].city;
                setGaCity(newGaCity);

                newGaState[i] = response.data[0].state;
                setGaState(newGaState);

                newGaZip[i] = response.data[0].zipCode;
                setGaZip(newGaZip);

                newGaCountry[i] = "USA";
                setGaCountry(newGaCountry);

                newGaSearchCode[i] = response.data[0].searchCode;
                setGaSearchCode(newGaSearchCode);
              }
            }
          } else {
            let newGaName = [];
            for (let i = 0; i < subPolicyCount; i++) {
              newGaName[i] = generalAgencyName;
            }
            setGaName(newGaName);
            setGeneralAgencyList([]);
          }
        } else {
          const updatedGeneralAgencyList = response.data.map((agency: any) => {
            return {
              ...agency,
              country: "USA",
            };
          });
          setGeneralAgencyList(updatedGeneralAgencyList);
        }
      })
      .catch((error: any) => {
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while loading the general agency details",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while loading the general agency details",
            life: 3000,
          });
        }
      });
  };
  const onCustomizeClick = () => {
    let widgetName = "newpremfi";
    let policyid = momentumPolicyId;
    navigate("/premiumfinance", { state: { widgetName, policyid } });
  };
  const onShowAllQuoteClick = () => {
    let showButton = !particularPremiumSettings.every((item: any) => item.id === 2);
    setHideShowAllQuoteBtn(true);
    if (particularPremiumSettings.length > 0) {
      particularPremiumSettings.forEach((item: any) => {
        switch (item.pfServiceId) {
          case 1:
            setGotoShowResponse(true);
            setGotoLogoUrl(item.logoUrl);
            break;
          case 2:
            setCapitalShowResponse(true);
            setCapitalLogoUrl(item.logoUrl);
            break;
          case 4:
            setIPFSShowResponse(true);
            setIpfsLogoUrl(item.logoUrl);
            break;
          case 5:
            setPeoplesShowResponse(true);
            setPeoplesLogoUrl(item.logoUrl);
            break;
          case 6:
            setFirstInsuranceFundingShowResponse(true);
            setFirstInsuranceFundingLogoUrl(item.logoUrl);
            break;
          case 7:
            setUsPfShowResponse(true);
            setUsPfLogoUrl(item.logoUrl);
            break;
          default:
            break;
        }
      });
    }
  }
  return (
    <div className="quick-quote-confirmation-main-page">
      <Toast ref={toast} />
      <Header pagename="QuickQuoteConfirmationPage" />
      <div className="content-wrapper ">
        <div className=" wrapper-main-row mx-auto wrapper-below-div">
          <div className="row main-content-row mx-auto w-100 position-relative ">
            {capitalShowResponse ? (
              <div className="row rounded-1 main-content-col ">
                <div className="col-12 text-end position-absolute">
                  {hideShowAllQuoteBtn ? null :
                    <AtkButtonIconBefore
                      label="Show All Quotes"
                      className="pf-secondary-btn w-auto m-2"
                      onClick={onShowAllQuoteClick}
                      isDisabled={particularPremiumSettings.length == 0 || (particularPremiumSettings.length == 1 && particularPremiumSettings[0].pfServiceId == 2)}
                      title={
                        particularPremiumSettings.length == 0 || (particularPremiumSettings.length == 1 && particularPremiumSettings[0].pfServiceId == 2) ?
                          "Please enable the expected premium finance companies toggle in PF settings to access all quotes or click customize button for more options."
                          : ""
                      }
                    />
                  }

                  <AtkButton
                    label="Customize"
                    className="pf-secondary-btn w-auto m-2"
                    onClick={onCustomizeClick}
                  ></AtkButton>
                </div>
                {capitalShowResponse ? (
                  <div className="form-group col-lg-4 col-md-6 col-12 mb-2 mt-5">
                    <CapitalResponse
                      businessProfile={businessProfile}
                      businessEmail={businessEmail}
                      businessPhoneNumber={businessPhoneNumber}
                      addLine1={addLine1}
                      addLine2={addLine2}
                      city={city}
                      sstate={sstate}
                      zipCode={zipCode}
                      country={country}
                      policyNumber={policyNumber}
                      coverageCode={coverageCode}
                      effectiveDate={effectiveDate}
                      term={term}
                      // currentPremium={currentPremium}
                      currentPremiumsArray={currentPremiumsArray}
                      firstName={firstName}
                      lastName={lastName}
                      clientId={clientId}
                      carrier={carrier}
                      carrierName={carrierName}
                      carrierAddress={carrierAddress}
                      carrierCity={carrierCity}
                      carrierState={carrierState}
                      carrierZip={carrierZip}
                      selectedPolicyIndex={policyIndex}
                      agentName={agentName}
                      agentPhoneNumber={agentPhone}
                      agentAddress={agentAddress}
                      agentCity={agentCity}
                      agentState={agentState}
                      agentZip={agentZip}
                      agentCountry={agentCountry}
                      brokerFee={brokerFee}
                      taxes={taxes}
                      fees={fees}
                      minimumEarned={minimumEarned}
                      nCPolicyId={nCPolicyId}
                      nCInsuredId={nCInsuredId}
                      ncPolicyNumber={policyNumber}
                      gaSearchCode={gaSearchCode}
                      gaName={gaName}
                      gaAddress={gaAddress}
                      gaCity={gaCity}
                      gaState={gaState}
                      gaZip={gaZip}
                      gaCountry={gaCountry}
                      capitalLogoUrl={capitalLogoUrl}
                      onCustomizeClick={onCustomizeClick}
                      // onMultiPolicyClick={onMultiPolicyClick}
                      target={capitalTarget}
                      showMultipolicyButton={false}
                    />
                  </div>
                ) : null}
                {iPFSShowResponse ? (
                  <div className="form-group col-lg-4 col-md-6 col-12 mb-2 mt-5">
                    <IpfsResponse
                      businessProfile={businessProfile}
                      businessEmail={businessEmail}
                      businessPhoneNumber={businessPhoneNumber}
                      addLine1={addLine1}
                      addLine2={addLine2}
                      city={city}
                      sstate={sstate}
                      zipCode={zipCode}
                      country={country}
                      policyNumber={policyNumber}
                      coverageCode={coverageCode}
                      effectiveDate={effectiveDate}
                      term={term}
                      // currentPremium={currentPremium}
                      currentPremiumsArray={currentPremiumsArray}
                      firstName={firstName}
                      lastName={lastName}
                      clientId={clientId}
                      carrierId={carrierValue.inputCode}
                      carrier={carrier}
                      carrierName={carrierName}
                      carrierAddress={carrierAddress}
                      carrierCity={carrierCity}
                      carrierState={carrierState}
                      carrierZip={carrierZip}
                      selectedPolicyIndex={policyIndex}
                      agentName={agentName}
                      agentPhoneNumber={agentPhone}
                      agentAddress={agentAddress}
                      agentCity={agentCity}
                      agentState={agentState}
                      agentZip={agentZip}
                      agentCountry={agentCountry}
                      brokerFee={brokerFee}
                      taxes={taxes}
                      fees={fees}
                      minimumEarned={minimumEarned}
                      nCPolicyId={nCPolicyId}
                      nCInsuredId={nCInsuredId}
                      ncPolicyNumber={policyNumber}
                      gaSearchCode={gaSearchCode}
                      gaName={gaName}
                      gaAddress={gaAddress}
                      gaCity={gaCity}
                      gaState={gaState}
                      gaZip={gaZip}
                      gaCountry={gaCountry}
                      iPFSLogoUrl={iPFSLogoUrl}
                    />
                  </div>
                ) : null}
                {gotoShowResponse ? (
                  <div className="form-group  col-lg-4 col-md-6 col-12 mb-2 mt-5">
                    <GotoPFResponse
                      businessProfile={businessProfile}
                      businessEmail={businessEmail}
                      businessPhoneNumber={businessPhoneNumber}
                      addLine1={addLine1}
                      addLine2={addLine2}
                      city={city}
                      sstate={sstate}
                      zipCode={zipCode}
                      country={country}
                      policyNumber={policyNumber}
                      coverageCode={coverageCode}
                      effectiveDate={effectiveDate}
                      term={term}
                      // currentPremium={currentPremium}
                      currentPremiumsArray={currentPremiumsArray}
                      firstName={firstName}
                      lastName={lastName}
                      clientId={clientId}
                      carrierId={carrierValue.inputCode}
                      carrierName={carrierName}
                      carrier={carrier}
                      carrierAddress={carrierAddress}
                      carrierCity={carrierCity}
                      carrierState={carrierState}
                      carrierZip={carrierZip}
                      selectedPolicyIndex={policyIndex}
                      agentName={agentName}
                      agentPhoneNumber={agentPhone}
                      agentAddress={agentAddress}
                      agentCity={agentCity}
                      agentState={agentState}
                      agentZip={agentZip}
                      agentCountry={agentCountry}
                      brokerFee={brokerFee}
                      taxes={taxes}
                      fees={fees}
                      minimumEarned={minimumEarned}
                      nCPolicyId={nCPolicyId}
                      nCInsuredId={nCInsuredId}
                      ncPolicyNumber={policyNumber}
                      gaSearchCode={gaSearchCode}
                      gaName={gaName}
                      gaAddress={gaAddress}
                      gaCity={gaCity}
                      gaState={gaState}
                      gaZip={gaZip}
                      gaCountry={gaCountry}
                      gotoLogoUrl={gotoLogoUrl}
                    />
                  </div>
                ) : null}
                {peoplesShowResponse ? (
                  <div className="form-group  col-lg-4 col-md-6 col-12 mb-2 mt-5">
                    <Peoples
                      businessProfile={businessProfile}
                      businessEmail={businessEmail}
                      businessPhoneNumber={businessPhoneNumber}
                      addLine1={addLine1}
                      addLine2={addLine2}
                      city={city}
                      sstate={sstate}
                      zipCode={zipCode}
                      country={country}
                      policyNumber={policyNumber}
                      coverageCode={coverageCode}
                      effectiveDate={effectiveDate}
                      term={term}
                      // currentPremium={currentPremium}
                      currentPremiumsArray={currentPremiumsArray}
                      firstName={firstName}
                      lastName={lastName}
                      clientId={clientId}
                      carrierId={carrierValue.inputCode}
                      carrierName={carrierName}
                      carrier={carrier}
                      carrierAddress={carrierAddress}
                      carrierCity={carrierCity}
                      carrierState={carrierState}
                      carrierZip={carrierZip}
                      selectedPolicyIndex={policyIndex}
                      agentName={agentName}
                      agentPhoneNumber={agentPhone}
                      agentAddress={agentAddress}
                      agentCity={agentCity}
                      agentState={agentState}
                      agentZip={agentZip}
                      agentCountry={agentCountry}
                      brokerFee={brokerFee}
                      taxes={taxes}
                      fees={fees}
                      minimumEarned={minimumEarned}
                      nCPolicyId={nCPolicyId}
                      nCInsuredId={nCInsuredId}
                      ncPolicyNumber={policyNumber}
                      gaSearchCode={gaSearchCode}
                      gaName={gaName}
                      gaAddress={gaAddress}
                      gaCity={gaCity}
                      gaState={gaState}
                      gaZip={gaZip}
                      gaCountry={gaCountry}
                      peoplesLogoUrl={peoplesLogoUrl}
                    />
                  </div>
                ) : null}
                {firstInsuranceFundingShowResponse ? (
                  <div className="form-group  col-lg-4 col-md-6 col-12 mb-2 mt-5">
                    <FirstInsuranceFunding
                      businessProfile={businessProfile}
                      businessEmail={businessEmail}
                      businessPhoneNumber={businessPhoneNumber}
                      addLine1={addLine1}
                      addLine2={addLine2}
                      city={city}
                      sstate={sstate}
                      zipCode={zipCode}
                      country={country}
                      policyNumber={policyNumber}
                      coverageCode={coverageCode}
                      effectiveDate={effectiveDate}
                      term={term}
                      // currentPremium={currentPremium}
                      currentPremiumsArray={currentPremiumsArray}
                      firstName={firstName}
                      lastName={lastName}
                      clientId={clientId}
                      carrierId={carrierValue.inputCode}
                      carrierName={carrierName}
                      carrier={carrier}
                      carrierAddress={carrierAddress}
                      carrierCity={carrierCity}
                      carrierState={carrierState}
                      carrierZip={carrierZip}
                      selectedPolicyIndex={policyIndex}
                      agentName={agentName}
                      agentPhoneNumber={agentPhone}
                      agentAddress={agentAddress}
                      agentCity={agentCity}
                      agentState={agentState}
                      agentZip={agentZip}
                      agentCountry={agentCountry}
                      brokerFee={brokerFee}
                      taxes={taxes}
                      fees={fees}
                      minimumEarned={minimumEarned}
                      nCPolicyId={nCPolicyId}
                      nCInsuredId={nCInsuredId}
                      ncPolicyNumber={policyNumber}
                      gaSearchCode={gaSearchCode}
                      gaName={gaName}
                      gaAddress={gaAddress}
                      gaCity={gaCity}
                      gaState={gaState}
                      gaZip={gaZip}
                      gaCountry={gaCountry}
                      firstInsuranceFundingLogoUrl={firstInsuranceFundingLogoUrl}
                    />
                  </div>
                ) : null}
                {usPfShowResponse ? (
                  <div className="form-group  col-lg-4 col-md-6 col-12 mb-2 mt-5">
                    <UsPremiumFinance
                      businessProfile={businessProfile}
                      businessEmail={businessEmail}
                      businessPhoneNumber={businessPhoneNumber}
                      addLine1={addLine1}
                      addLine2={addLine2}
                      city={city}
                      sstate={sstate}
                      zipCode={zipCode}
                      country={country}
                      policyNumber={policyNumber}
                      coverageCode={coverageCode}
                      effectiveDate={effectiveDate}
                      term={term}
                      // currentPremium={currentPremium}
                      currentPremiumsArray={currentPremiumsArray}
                      firstName={firstName}
                      lastName={lastName}
                      clientId={clientId}
                      carrierId={carrierValue.inputCode}
                      carrierName={carrierName}
                      carrier={carrier}
                      carrierAddress={carrierAddress}
                      carrierCity={carrierCity}
                      carrierState={carrierState}
                      carrierZip={carrierZip}
                      selectedPolicyIndex={policyIndex}
                      agentName={agentName}
                      agentPhoneNumber={agentPhone}
                      agentAddress={agentAddress}
                      agentCity={agentCity}
                      agentState={agentState}
                      agentZip={agentZip}
                      agentCountry={agentCountry}
                      brokerFee={brokerFee}
                      taxes={taxes}
                      fees={fees}
                      minimumEarned={minimumEarned}
                      nCPolicyId={nCPolicyId}
                      nCInsuredId={nCInsuredId}
                      ncPolicyNumber={policyNumber}
                      gaSearchCode={gaSearchCode}
                      gaName={gaName}
                      gaAddress={gaAddress}
                      gaCity={gaCity}
                      gaState={gaState}
                      gaZip={gaZip}
                      gaCountry={gaCountry}
                      usPfLogoUrl={usPfLogoUrl}
                    />
                  </div>
                ) : null}
              </div>
            ) : (
              <div className="col-lg-12 col-md-12 col-sm-12 rounded-1 main-content-col ">
                {nowCertsClientsByIdLoading ? (
                  <div className="d-flex align-items-center justify-content-center mt-5">
                    <AtkLoader />
                  </div>
                ) : (
                  <div className="row p-3">
                    <div className="col-12 mt-3">
                      <div className="separator">
                        <div className="line left"></div>
                        <span>Insured Details</span>
                        <div className="line right"></div>
                      </div>
                    </div>

                    <div className="col-12">
                      {businessProfile}
                      <address>
                        {addLine1}, {addLine2},<br />
                        {city}, {sstate}, {zipCode}, {country} <br />
                        {businessPhoneNumber}
                        <br />
                        {businessEmail}
                      </address>
                    </div>
                    <div className="col-12 mt-3">
                      <div className="separator">
                        <div className="line left"></div>
                        <span>Agent Details</span>
                        <div className="line right"></div>
                      </div>
                    </div>

                    <div className="col-12">
                      {agentName}
                      <address>
                        {agentAddress},<br />
                        {agentCity}, {agentState}, {agentZip}, {agentCountry} <br />
                        {agentPhone}
                      </address>
                    </div>
                    <div className="col-12 mt-3">
                      <div className="separator">
                        <div className="line left"></div>
                        <span>Policies</span>
                        <div className="line right"></div>
                      </div>
                    </div>
                    {subPolicyLobs != undefined && subPolicyLobs.length > 0
                      ? subPolicyLobs.map((code: any, index: any) => {
                        return (
                          <>
                            <div className="col-6 border-end mt-2">
                              <div className="row ">
                                <div className="col-lg-4 col-md-3 col-sm-6 col-6 py-1  d-flex align-items-center  ">
                                  <span className="fw-bold"> Policy Number:&nbsp; </span>
                                  <span>{policyNumber[selectedSubPolicy]} </span>
                                </div>

                                <div className="col-lg-4 col-md-3 col-sm-6 col-6 py-1  d-flex align-items-center ">
                                  <span className="fw-bold">Effective Date : &nbsp; </span>
                                  <span>{moment(effectiveDate[selectedSubPolicy]).format("YYYY-MM-DD")}</span>
                                </div>

                                <div className="col-lg-4 col-md-3 col-sm-6 col-6 py-1  d-flex align-items-center ">
                                  <span className="fw-bold">Current Premium : &nbsp;</span>
                                  <span>$ {currentPremiumsArray[selectedSubPolicy]}</span>
                                </div>

                                <div className="col-lg-4 col-md-3 col-sm-6 col-6 py-1  d-flex align-items-center ">
                                  <span className="fw-bold">Coverage Code : &nbsp;</span>
                                  <span>
                                    {inputValue &&
                                      Array.isArray(inputValue) &&
                                      inputValue[selectedSubPolicy] &&
                                      inputValue[selectedSubPolicy].description
                                      ? inputValue[selectedSubPolicy].description
                                      : "Search"}
                                  </span>
                                </div>

                                <div className="col-lg-4 col-md-3 col-sm-6 col-6 py-1  d-flex align-items-center ">
                                  <span className="fw-bold">Taxes : &nbsp;</span>
                                  <span>$ {taxes[selectedSubPolicy]}</span>
                                </div>

                                <div className="col-lg-4 col-md-3 col-sm-6 col-6 py-1  d-flex align-items-center ">
                                  <span className="fw-bold">Fees : &nbsp; </span>
                                  <span>$ {fees[selectedSubPolicy]}</span>
                                </div>

                                <div className="col-lg-4 col-md-3 col-sm-6 col-6 py-1  d-flex align-items-center ">
                                  <span className="fw-bold">Minimum Earned % : &nbsp;</span>
                                  <span>{minimumEarned[selectedSubPolicy]}</span>
                                </div>

                                <div className="col-lg-4 col-md-3 col-sm-6 col-6 py-1  d-flex align-items-center ">
                                  <span className="fw-bold">Broker Fee : &nbsp;</span>
                                  <span>$ {brokerFee != "" ? brokerFee : "0.00"}</span>
                                </div>

                                <div className="col-lg-4 col-md-3 col-sm-6 col-6 py-1  d-flex align-items-center ">
                                  <span className="fw-bold"> Term : &nbsp;</span>
                                  <span>{term} </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-3 mt-2 border-end">
                              <div className="row ">
                                {carrierName[selectedSubPolicy] && carrierName[selectedSubPolicy].length > 0 && !showCarrierDropDown ? (
                                  <div className="col-10  position-relative ">
                                    <span className="fw-bold">
                                      {" "}
                                      Carrier :{" "}
                                      <i
                                        title="Edit"
                                        className="fa fa-pencil text-secondary show-cursor position-absolute end-0 me-2 text-16"
                                        onClick={onCarrierEditIconClick}
                                      />
                                    </span>
                                    <br />

                                    {carrierName[selectedSubPolicy]}
                                    <address>
                                      {carrierAddress[selectedSubPolicy]},<br />
                                      {carrierCity[selectedSubPolicy]}, {carrierState[selectedSubPolicy]}, {carrierZip[selectedSubPolicy]},{" "}
                                      {carrierCountry[selectedSubPolicy]} <br />
                                    </address>
                                    <span className="text-10 alert alert-warning p-1">
                                      * Carrier can be changed by clicking the edit icon on top right.{" "}
                                    </span>
                                  </div>
                                ) : (
                                  <div className="form-group col-10 py-1  position-relative   ">
                                    <label className="mb-1">
                                      Carrier Name<span className="text-danger ">*</span>
                                      <i
                                        title="Close"
                                        className="fa fa-close text-danger show-cursor position-absolute end-0 me-2 text-16"
                                        onClick={onCarrierEditIconClick}
                                      />
                                    </label>{" "}
                                    <div
                                      className={
                                        carrierNameError[index] != undefined && carrierNameError[index].length > 0
                                          ? "form-group col-12 mb-2 position-relative validation-div"
                                          : "form-group col-12 mb-2 position-relative"
                                      }
                                    >
                                      <AtkTextField
                                        className="input-field policydetails"
                                        type="text"
                                        id="text"
                                        value={carrierSearch.length > 0 ? carrierSearch[index] : ""}
                                        onChange={onCarrierNameChange}
                                        placeholder={
                                          carrierValue[index] != undefined &&
                                            carrierValue[index] != null &&
                                            carrierValue[index].description != undefined &&
                                            carrierValue[index].description != null &&
                                            carrierValue[index].description.trim() != ""
                                            ? carrierValue[index].description
                                            : "Search and select carrier name..."
                                        }
                                        autoComplete="off"
                                      />
                                      {carrierSearch[index] != undefined && carrierSearch[index] != null && carrierSearch.length > 0 && (
                                        <>
                                          {carrierList.length > 0 ? (
                                            <div id="hs-client-search" className="canopy-clientselector-dropdown w-100">
                                              {carrierList.map((item: any) => (
                                                <div
                                                  className="show-cursor w-100"
                                                  onClick={(e) => {
                                                    onCarrierselect(item, e);
                                                  }}
                                                >
                                                  <a href="#" className="carrier-link">
                                                    {item.description}
                                                  </a>
                                                </div>
                                              ))}
                                            </div>
                                          ) : (
                                            <>
                                              {carrierSearch[index].length > 0 ?
                                                <div
                                                  style={{
                                                    background: "#ddd",
                                                  }}
                                                  className="text-center text-12 w-100 p-2 "
                                                >
                                                  <span className="">Oops Carrier name not found</span>
                                                </div>
                                                :
                                                null}
                                            </>
                                          )}
                                        </>
                                      )}
                                      <span className="text-12 text-danger">{carrierNameError}</span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-3  mt-2">
                              <div className="row ">
                                {gaName[selectedSubPolicy].length && !showGeneralAgencyDropDown ? (
                                  <div className="col-10  position-relative ">
                                    <span className="fw-bold">
                                      {" "}
                                      General Agency :{" "}
                                      <i
                                        title="Edit"
                                        className="fa fa-pencil text-secondary show-cursor position-absolute end-0 me-2 text-16"
                                        onClick={onGeneralAgencyEditIconClick}
                                      />
                                    </span>
                                    <br />

                                    {gaName[selectedSubPolicy]}
                                    <address>
                                      {gaAddress[selectedSubPolicy]},<br />
                                      {gaCity[selectedSubPolicy]}, {gaState[selectedSubPolicy]}, {gaZip[selectedSubPolicy]},{" "}
                                      {gaCountry[selectedSubPolicy]} <br />
                                    </address>
                                    <span className="text-10 alert alert-warning p-1">
                                      * General agency can be changed by clicking the edit icon on top right.{" "}
                                    </span>
                                  </div>
                                ) : (
                                  <div className="form-group col-10 py-1  position-relative   ">
                                    <label className="mb-1">
                                      General Agency
                                      {gaName[selectedSubPolicy].length && showGeneralAgencyDropDown ? (
                                        <i
                                          title="Close"
                                          className="fa fa-close text-danger show-cursor position-absolute end-0 me-2 text-16"
                                          onClick={onGeneralAgencyEditIconClick}
                                        />
                                      ) : null}
                                    </label>{" "}
                                    <div className="form-group col-12 mb-2 position-relative">
                                      <AtkTextField
                                        className="input-field"
                                        type="text"
                                        id="text"
                                        value={gaName[index]}
                                        onChange={onGeneralAgencySearchChange}
                                        placeholder="Enter general agency name"
                                        autoComplete="off"
                                      />
                                      {generalAgencyList.length > 0 ? (
                                        <div id="hs-client-search" className="canopy-clientselector-dropdown w-100">
                                          {generalAgencyList.map((item: any) => (
                                            <div
                                              className="show-cursor w-100"
                                              onClick={(e) => {
                                                onGeneralAgencySelect(item, e);
                                              }}
                                            >
                                              <a href="#" className="carrier-link">
                                                {item.description}
                                              </a>
                                            </div>
                                          ))}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-12 mt-3">
                              <div className="separator">
                                <div className="line whole"></div>
                              </div>
                            </div>
                          </>
                        );
                      })
                      : null}

                    <div className="col-12 text-end position-absolute " style={{ bottom: "10px", right: "10px" }}>
                      <AtkButton label="Submit" onClick={onSubmitClick} className="pf-primary-btn w-auto" />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div >
  );
}