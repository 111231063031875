import React, { FC, useEffect, useState } from "react";
import MoreBtn from "../../assets/images/continue.png";
import copyImg from "../../assets/images/copy.png";
import moment from "moment";

export default function FinalQuotes(props: any) {
  const [heightFull, setHeightFull] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const quoteData = {
    transactionId: "91b9d160-d042-40fc-91cb-2b4a7aa38614",
    quoteNumber: "3355",
    totalPaymentAmount: 0,
    totalPremium: 9052,
    downPayment: 3168.2,
    amountFinanced: 5883.8,
    financeCharge: 337.73,
    docStampTax: 0,
    nonRefundableFee: 0,
    numberOfInstallments: 7,
    installmentAmount: 888.79,
    effectiveAPR: "16.98",
    documentURL: "",
    message: "Quote generated!",
    brokerFee: 0,
    otherFees: 0,
    otherTaxes: 0,
    quoteDate: "2023-12-20T14:16:21.6177498Z",
  };

  const onMoreButtonClick = () => {
    setHeightFull(!heightFull);
  };
  const CopyToClipBoardClicked = () => {
    if (props.premiumresponse && props.premiumresponse.quoteNumber) {
      const quoteNumber = props.premiumresponse.quoteNumber;
      navigator.clipboard.writeText(quoteNumber)
        .then(() => {
          setSuccessMessage("Copied!");
          setTimeout(() => {
            setSuccessMessage("");
          }, 5000);
        })
        .catch(() => {
          setErrorMessage("Failed to copy.");
          setTimeout(() => {
            setErrorMessage("");
          }, 5000);
        });
    }
  };
  return (
    <>
      <table
        className={
          heightFull ? "text-12 my-1 final-quotes-table quote-response-height-large" : "text-12 my-1 final-quotes-table quote-response-height-short"
        }
      >
        <tr>
          <td className="fw-bold"> Quote Number</td>
          <td style={{ position: "relative" }}>
            {props.premiumresponse?.quoteNumber == "" || props.premiumresponse?.quoteNumber == null ?
              '0' : props.premiumresponse?.quoteNumber}
            {props.premiumresponse?.quoteNumber ? (
              <>
                <img src={copyImg} onClick={CopyToClipBoardClicked} className="copy-icon mx-2" alt="" style={{ cursor: "pointer" }} />
                <span className="text-success" style={{ position: "absolute", top: "50%", transform: "translateY(-50%)" }}>
                  {successMessage}
                </span>
                <span className="text-danger" style={{ position: "absolute", top: "50%", transform: "translateY(-50%)" }}>
                  {" "}
                  {errorMessage}
                </span>
              </>
            ) : null}
          </td>
        </tr>

        <tr>
          <td className="fw-bold">Total Premium</td>
          <td>
            {props.premiumresponse?.totalPremium == "" || props.premiumresponse?.totalPremium == null
              ? "$0.00"
              : parseFloat(props.premiumresponse?.totalPremium).toLocaleString("en-US", { style: "currency", currency: "USD" })}
          </td>
        </tr>

        <tr>
          <td className="fw-bold">Downpayment</td>
          <td>
            {" "}
            {props.premiumresponse?.downPayment == "" || props.premiumresponse?.downPayment == null
              ? "$0.00"
              : parseFloat(props.premiumresponse?.downPayment).toLocaleString("en-US", { style: "currency", currency: "USD" })}
          </td>
        </tr>
        <tr>
          <td className="fw-bold">Installment Amount</td>
          <td>
            {" "}
            {props.premiumresponse?.installmentAmount == "" || props.premiumresponse?.installmentAmount == null
              ? "$0.00"
              : parseFloat(props.premiumresponse?.installmentAmount).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}{" "}
          </td>
        </tr>
        <tr>
          <td className="fw-bold">Effective APR(%)</td>
          <td>
            {" "}
            {props.premiumresponse?.effectiveAPR == "" || props.premiumresponse?.effectiveAPR == null
              ? 0
              : `${parseFloat(props.premiumresponse?.effectiveAPR).toFixed(3)}%`}
          </td>
        </tr>
        {heightFull ? (
          <>
            <tr>
              <td className="fw-bold">Financed Amount</td>
              <td>
                {" "}
                {props.premiumresponse?.amountFinanced == "" || props.premiumresponse?.amountFinanced == null
                  ? "$0.00"
                  : parseFloat(props.premiumresponse?.amountFinanced).toLocaleString("en-US", { style: "currency", currency: "USD" })}{" "}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">Finance Charge</td>
              <td>
                {" "}
                {props.premiumresponse?.financeCharge == "" || props.premiumresponse?.financeCharge == null
                  ? "$0.00"
                  : parseFloat(props.premiumresponse?.financeCharge).toLocaleString("en-US", { style: "currency", currency: "USD" })}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">Document Stamp Tax</td>
              <td>
                {" "}
                {props.premiumresponse?.docStampTax == "" || props.premiumresponse?.docStampTax == null
                  ? parseFloat("0").toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })
                  : parseFloat(props.premiumresponse?.docStampTax).toLocaleString("en-US", { style: "currency", currency: "USD" })}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">Non Refundable Fee</td>
              <td>
                {" "}
                {props.premiumresponse?.nonRefundableFee == "" || props.premiumresponse?.nonRefundableFee == null
                  ? parseFloat("0").toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })
                  : parseFloat(props.premiumresponse?.nonRefundableFee).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">Number of Installments</td>
              <td> {props.premiumresponse?.numberOfInstallments == "" || props.premiumresponse?.numberOfInstallments == null ?
                '0' : props.premiumresponse?.numberOfInstallments}</td>
            </tr>

            <tr>
              <td className="fw-bold">Broker Fee</td>
              <td>
                {props.premiumresponse?.brokerFee == "" || props.premiumresponse?.brokerFee == null
                  ? parseFloat("0").toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })
                  : parseFloat(props.premiumresponse?.brokerFee).toLocaleString("en-US", { style: "currency", currency: "USD" })}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">Other Taxes</td>
              <td>
                {" "}
                {props.premiumresponse?.otherTaxes == "" || props.premiumresponse?.otherTaxes == null
                  ? parseFloat("0").toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })
                  : parseFloat(props.premiumresponse?.otherTaxes).toLocaleString("en-US", { style: "currency", currency: "USD" })}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">Other Fees</td>
              <td>
                {" "}
                {props.premiumresponse?.otherFees == "" || props.premiumresponse?.otherFees == null
                  ? parseFloat("0").toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })
                  : parseFloat(props.premiumresponse?.otherFees).toLocaleString("en-US", { style: "currency", currency: "USD" })}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">Quote Date</td>
              <td>
                {" "}
                {props.premiumresponse?.quoteDate != null && props.premiumresponse?.quoteDate != ""
                  ? moment(props.premiumresponse.quoteDate).format("YYYY-MM-DD")
                  : null}
              </td>
            </tr>
          </>
        ) : null}
        <tr>
          <td></td>
          <td className="text-end ">
            <img src={MoreBtn} onClick={onMoreButtonClick} className="show-cursor" title={heightFull ? "Show less" : "Show more"} />
          </td>
        </tr>
      </table>
    </>
  );
}