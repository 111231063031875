import { useEffect, useState, useRef } from "react";
import { InputSwitch } from "primereact/inputswitch";
import CommonValues from "../common/utils";
import axios from "axios";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";

export default function FinanceCompanyList(props: any) {
  const toast: any = useRef("");
  const [premiumLoading, setPremiumLoading] = useState(false);
  const [saveSettingLoading, setSaveSettingLoading] = useState(false);
  const [premiumSettings, setPremiumSettings] = useState<any>([]);
  const [premiumSettingsCurrent, setPremiumSettingsCurrent] = useState<any>([]);
  const navigate = useNavigate();
  const [couponCodeLoading, setCouponCodeLoading] = useState(false);
  const [CouponCode, setCouponCode] = useState("");
  const [companyIndex, setCompanyIndex] = useState(-1);
  useEffect(() => {
    getGigCode();
  }, []);
  const getGigCode = () => {
    let token = CommonValues.GetToken();
    setCouponCodeLoading(true);
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/couponcodebyuserid`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((response: any) => {
        setCouponCodeLoading(false);
        setCouponCode(response.data);
        getPremiumFinanceSettings(response.data);
      })
      .catch((error: any) => {
        console.log(error);
        setCouponCodeLoading(false);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while updating pfServiceId setup state";
          }
        } else {
          errorMessage = "Unknown error while updating pfServiceId setup state";
        }
      });
  };
  const getPremiumFinanceSettings = (couponcode: any) => {
    setPremiumLoading(true);
    var token = CommonValues.GetToken();

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/premiumsetting`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setPremiumSettings(response.data);
        let technology: any = [];

        response.data.forEach((item: any) => {
          if (item.id === 2) {
            technology.push({ id: item.id, name: item.name, isChecked: true });
          } else {
            technology.push({ id: item.id, name: item.name, isChecked: false });
          }
        });

        setPremiumSettings(technology);
        if (couponcode.length == 0) {
          onSaveSettings(technology, false);
        }
        setPremiumLoading(false);
      })
      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while loading the Premium finance Setting details", life: 3000 });
          }
        } else {
          toast.current.show({ severity: "error", detail: "Unknown error while loading the Premium finance Setting details", life: 3000 });
        }
        setPremiumLoading(false);
      });
  };
  const getPremiumFinanceSettingsById = (premiumSettings: any) => {
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/pfcompanies`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        let technology: any = [];
        premiumSettings.forEach((item: any) => {
          if (response.data.some((res: any) => res.pfServiceId === item.id)) {
            technology.push({ id: item.id, name: item.name, isChecked: true });
          } else {
            technology.push({ id: item.id, name: item.name, isChecked: false });
          }
        });
        setPremiumSettings(technology);
        setSaveSettingLoading(false);
        const gotoPremiumFinance = technology.find((item: any) => item.name === "gotoPremiumFinance");
        const capitalPremiumFinancing = technology.find((item: any) => item.name === "Capital Premium Financing");
        const ipfs = technology.find((item: any) => item.name === "IPFS");
        const peoples = technology.find((item: any) => item.name === "Peoples Premium Finance");
        const firstInsuranceFunding = technology.find((item: any) => item.name === "First Insurance Funding");
        const usPremiumFinance = technology.find((item: any) => item.name === "US Premium Finance");
      })
      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the Premium finance Setting details by id";
          }
        }
        toast.current.show({ severity: "error", detail: { errorMessage }, life: 3000 });

        setSaveSettingLoading(false);
      });
  };
  const updateWizardSetupState = () => {
    var token = CommonValues.GetToken();

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/updatewizardsetup?IsWizardSetupDone=${true}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
      },
    };
    axios(config)
      .then((response: any) => {
        if (response.data.success == true) {
          localStorage.setItem("isSetupCompleted", "true");
          localStorage.removeItem("currentPage");
          navigate("/landingpage");
        }
      })
      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while updating wizard setup state";
          }
        } else {
          errorMessage = "Unknown error while  updating wizard setup state";
        }
        toast.current.show({ severity: "error", detail: { errorMessage }, life: 3000 });
      });
  };

  const onCheckboxChange = (e: any, i: any) => {
    setSaveSettingLoading(true);
    setCompanyIndex(i);
    const updatedPremiumSettings = premiumSettings.map((item: any, k: any) => {
      if (k === i) {
        item.isChecked = !item.isChecked;
      }
      return item;
    });

    setPremiumSettings(updatedPremiumSettings);
    onSaveSettings(updatedPremiumSettings, true);
  };

  const onSaveSettings = (premiumSettings: any, showSavedToastMessage: any) => {
    let premiumSettingsCurrent = [];
    for (let k = 0; k < premiumSettings.length; k++) {
      if (premiumSettings[k].isChecked == true) {
        premiumSettingsCurrent.push(premiumSettings[k]);
      }
    }

    setPremiumSettingsCurrent(premiumSettingsCurrent);
    props.onPremiumSettingsUpdate(premiumSettingsCurrent);

    setSaveSettingLoading(true);
    var token = CommonValues.GetToken();

    var data = JSON.stringify(premiumSettingsCurrent);
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/insertpfsettings`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then((response: any) => {
        if (showSavedToastMessage) {
          toast.current.show({ severity: "success", detail: "Settings saved successfully", life: 3000 });
        }
        setTimeout(() => {
          getPremiumFinanceSettingsById(premiumSettings);
        }, 500);
      })
      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while inserting the Premium finance Setting details";
          }
        } else {
          errorMessage = "Unknown error while inserting the Premium finance Setting details";
        }
        toast.current.show({ severity: "error", detail: { errorMessage }, life: 3000 });
        setSaveSettingLoading(false);
      });
  };
  return (
    <>
      <Toast ref={toast} />
      <div className="row mx-auto border rounded  company-list-setup-mainpage">

        <div className="col-lg-4 col-md-5 col-sm-12 col-12 company-list-block pt-3">
          <div className="row mx-1 ">
            {premiumLoading ? (
              <div className="col-12 d-flex justify-content-center align-items-center">
                <AtkLoader />{" "}
              </div>
            ) : (
              <>
                {premiumSettings.map((i: any, index: number) => {
                  return (
                    <div className="col-12 finance-company" key={index + 1}>
                      <span className="">{i.name}</span>
                      {saveSettingLoading && companyIndex == index ? (
                        <span className="pt-1" style={{ margin: "5px" }}>
                          <AtkLoader />
                        </span>
                      ) : (
                        <InputSwitch className="" checked={i.isChecked} onChange={(e: any) => onCheckboxChange(e, index)} />
                      )}
                    </div>
                  );
                })}
              </>
            )}
          </div>
          <div className="col"></div>
        </div>
      </div>
    </>
  );
}
