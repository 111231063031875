import axios from "axios";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AtkButton from "../../AtlaskitControls/AtkButton";
import Button, { ButtonProps } from "@atlaskit/button";
import AtkButtonIconBefore from "../../AtlaskitControls/AtkButtonIconBefore";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import AtkMessage from "../../AtlaskitControls/AtkMessage";
import PfdIcon from "../../assets/images/file.png";
import PreviewIcon from "../../assets/images/paper.png";
import RemoveIcon from "../../assets/images/trashcan.png";
import CommonValues from "../../common/utils";
import { Toast } from "primereact/toast";
import NewInsuredPage from "./NewInsuredPage";
import { OverlayPanel } from "primereact/overlaypanel";
import SectionMessage from "@atlaskit/section-message";

export default function PFOcrScan(props: any) {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const feedbackRef = useRef<OverlayPanel>(null);
  const [uploadFile, setUploadFile] = useState<any>([]);
  const [uploadFileLimit, setUploadFileLimit] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [filesCount, setFilesCount] = useState(0);
  const [requestFileCount, setRequestFileCount] = useState("");
  const [uploadingFiles, setUploadingFiles] = useState(false);
  const [uploadingRetry, setUploadingRetry] = useState(false);
  const [incompleteRequestData, setIncompleteRequestData] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isfileuploaded, setIsfileuploaded] = useState(false);
  const [fileNameTitle, setFileNameTitle] = useState("");
  const [fileName, setFileName] = useState("");
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [uploadPdfUrl, setUploadPdfUrl] = useState("");
  const [quoteNumber, setQuoteNumber] = useState("");
  const [premium, setPremium] = useState("");
  const [downPayment, setDownPayment] = useState("");
  const [financedAmount, setFinancedAmount] = useState("");
  const [effectiveAPR, setEffectiveAPR] = useState("");
  const [otherTaxes, setOtherTaxes] = useState("");
  const [otherFees, setOtherFees] = useState("");
  const [brokerFee, setBrokerFee] = useState("0");
  const [searchClientList, setSearchClientList] = useState<any>([]);
  const [searchClientLoading, setSearchClientLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [clientName, setClientName] = useState("");
  const [nowCertPolicyData, setNowCertPolicyData] = useState<any>([]);
  const [selectedPolicyIds, setSelectedPolicyIds] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [insuredNoPolicyError, setInsuredNoPolicyError] = useState("");
  const [submissionId, setSubmissionId] = useState("");
  const [clientNameErrorMessage, setClientNameErrorMessage] = useState("");
  const [uploadagain, setUploadAgain] = useState(false);
  const [grossPremiumList, setGrossPremiumList] = useState<any>([]);
  const [policyLoading, setPolicyLoading] = useState(false);
  const [selectedInsuredDetails, setSelectedInsuredDetails] = useState(0);
  const [premiumErrorMessage, setPremiumErrorMessage] = useState(Array().fill([]));
  const [selectedClientName, setSelectedClientName] = useState("");
  const [currentBalanceLoading, setCurrentBalanceLoading] = useState(false);
  const [currentBalance, setCurrentBalance] = useState("0");
  const [state, setState] = useState("");
  const [insuredEmail, setInsuredEmail] = useState("");
  const [insuredPhoneNumber, setInsuredPhoneNumber] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [policyNumber, setPolicyNumber] = useState("");
  const [carrierName, setCarrierName] = useState<string>("");
  const [effectiveDate, setEffectiveDate] = useState("");
  const [term, setTerm] = useState("");
  const [insuredFirstName, setInsuredFirstName] = useState("");
  const [insuredLastName, setInsuredLastName] = useState("");
  const [insuredDataBaseId, setInsuredDatabaseId] = useState("");
  const [createInsured, setCreateInsured] = useState(false);
  const [createPolicy, setCreatePolicy] = useState(false);
  const [lineOfBusiness, setLineOfBusiness] = useState("");
  const [insuredFirstNameError, setInsuredFirstNameError] = useState("");
  const [insuredLastNameError, setInsuredLastNameError] = useState("");
  const [policyNumberError, setPolicyNumberError] = useState("");
  const [premiumAmountError, setPremiumAmountError] = useState("");
  const [effectiveDateError, setEffectiveDateError] = useState("");
  const [coverageCodeError, setCoverageCodeError] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");
  const [stateCodeError, setStateCodeError] = useState("");
  const [termError, setTermError] = useState("");
  const [showPolicyExistMessage, setShowPolicyExistMessage] = useState(false);
  const policyMessageRef = useRef<OverlayPanel>(null);
  const [feedBackData, setFeedBackData] = useState<any>([]);
  const [feedBackError, setFeedBackError] = useState("");
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [ncURL, setNcURL] = useState("");
  const [insuranceCompanyName, setInsuranceCompanyName] = useState("");

  let options1 = [
    { value: "CapitalPremiumFinancing", label: "Capital Premium Financing" },
    { value: "IPFS", label: "IPFS" },
  ];

  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      navigate("/");
      CommonValues.Logout(navigate);
    }
    if (search == "") {
      return;
    }
    setSearchClientLoading(true);
    if (CommonValues.GetTenantId() == process.env.REACT_APP_NowCerts_Tenant_Id) {
      const searchTimeout = setTimeout(() => {
        getNowCertsClients(search);
      }, 10);
      return () => clearTimeout(searchTimeout);
    }
  }, [search]);

  useEffect(() => {
    getCardDetails();
  }, []);

  useEffect(() => {
    if (props.prospectDetails == "Create Client") {
      setCreateInsured(true);
    } else {
      setCreateInsured(false);
    }
    if (props.policyDetails == "Create Policy") {
      setCreatePolicy(true);
    } else {
      setCreatePolicy(false);
    }
  }, [props.prospectDetails, props.policyDetails]);

  const onClientSearchChange = (value: any) => {
    if (value.length > 255) {
      return;
    }
    setSearch(value);
    setClientName(value);
  };

  const getCardDetails = () => {
    setCurrentBalanceLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/current-balance`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.success) {
          setCurrentBalance((response.data.currentBalance / 100).toFixed(2));
        }
        setCurrentBalanceLoading(false);
      })
      .catch((error) => {
        setCurrentBalanceLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while getting current balance",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while getting current balance",
            life: 3000,
          });
        }
      });
  };

  const onDropHandler = (event: any) => {
    event.preventDefault();
    onFileSelectHandler(event.dataTransfer.files);
  };

  const onFileSelectHandler = (files: any) => {
    setFileName("");
    setFileNameTitle("");
    const isValidated = validateSelectedFiles(files);
    if (!isValidated) {
      setErrorMessage("Please select PDF files only");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      return false;
    }

    let fileList = [];
    let totalSize = 0;
    let currentFiles = uploadFile;
    let filename = files[0].name;
    if (filename.length > 50) {
      let shortenString = filename.substring(0, 50).concat("...");
      setFileName(shortenString);
      setFileNameTitle(filename);
    } else {
      setFileName(filename);
      setFileNameTitle(filename);
    }
    for (let i = 0, len = files.length; i < len; i++) {
      fileList.push({
        file: files[i],
        name: files[i].name,
        size: files[i].size,
      });
      totalSize = totalSize + files[i].size;
    }

    if (fileList.length > 0) {
      if (currentFiles.length > 0) {
        for (var i = 0, l = currentFiles.length; i < l; i++) {
          for (var j = 0; j < fileList.length; j++) {
            if (currentFiles[i].name === fileList[j].name) {
              fileList.splice(j, 1);
            }
          }
        }
      }
      let allFiles = currentFiles.concat(fileList);
      if (allFiles.length > uploadFileLimit) {
        allFiles = allFiles.slice(0, uploadFileLimit);
        setErrorMessage("Multiple files cannot be uploaded at the same time; please upload a single file");
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
      setUploadFile(allFiles);
      setFilesCount(allFiles.length);
      setRequestFileCount(allFiles.length);
      setFilesCount(allFiles.length);
    }
  };

  const validateSelectedFiles = (files: any) => {
    var regularExpression = /(?:\.([^.]+))?$/;
    var isValid = true;

    for (let index = 0, len = files.length; index < len; index++) {
      const fileExtension: any = regularExpression.exec(files[index].name);
      if (fileExtension === undefined || fileExtension[0] == "") {
        isValid = false;
        break;
      }
      if (fileExtension?.[1].toLowerCase().includes("pdf")) {
        isValid = true;
      } else {
        isValid = false;
        break;
      }
    }
    return isValid;
  };

  const removeFileFromList = () => {
    setUploadFile([]);
    setIsfileuploaded(false);
    setSuccessMessage("");
  };

  const createUploadRequest = () => {
    setLoading(true);

    if (uploadFile.length == 0) {
      setErrorMessage("Please select at least one file to upload");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      setLoading(false);
      return;
    }

    var FormData = require("form-data");
    var data = new FormData();
    data.append("file", uploadFile[0].file);
    data.append("insuranceCompanyName", insuranceCompanyName);
    let token = CommonValues.GetToken();
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/pfscan`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
    axios(config)
      .then((response: any) => {
        if (insuranceCompanyName == "CapitalPremiumFinancing") {
          CPFResponseDataMap(response);
        } else if (insuranceCompanyName == "IPFS") {
          IPFSResponseDataMap(response);
        }
        setLoading(false);
        getCardDetails();
      })
      .catch((error: any) => {
        console.log(error);
        localStorage.removeItem("currentPage");
        if (error.response != null && error.response.status == 401) {
          navigate("/");
          CommonValues.Logout(navigate);
        } else {
          setErrorMessage("Error while creating the request.");
          setTimeout(() => {
            setErrorMessage("");
          }, 3000);
          setLoading(false);
        }
      });
  };

  const CPFResponseDataMap = (response: any) => {
    let submissionId = response.data.submissionId;
    let grossPremiumList = response.data.grossPremium;
    setGrossPremiumList(grossPremiumList);
    let premiums: any = [];
    let fees: any = [];
    let taxes: any = [];
    let downPayment: any = [];
    let financedAmount: any = [];
    let policyNumber: any = [];
    let term: any = [];
    let carrierName: any = [];
    let lineOfBusiness: any = [];
    let effectiveDate: any = [];
    grossPremiumList.forEach((item: any, index: any) => {
      premiums.push(item.premium == null ? 0 : item.premium.replace(",", ""));
      fees.push(item.fee == null ? 0 : item.fees.replace(",", ""));
      taxes.push(item.taxes == null ? 0 : item.taxes.replace(",", ""));
      downPayment.push(item.downPayment == null ? 0 : item.downPayment.replace(",", ""));
      financedAmount.push(item.amountFinanced == null ? 0 : item.amountFinanced.replace(",", ""));
      policyNumber.push(item.policyNumber);
      term.push(item.term);
      carrierName.push(item.carrierName);
      lineOfBusiness.push(item.lineOfBusiness);
      effectiveDate.push(item.effectiveDate);
    });
    setAddressLine1(response.data.addressLine1);
    setCity(response.data.city.replace(",", ""));
    setZipCode(response.data.zipCode);
    setState(response.data.state);
    if (props.policyDetails != "Create Policy") {
      setPolicyNumber(props.existingPolicyId);
    } else {
      setPolicyNumber(grossPremiumList[0].policyNumber);
    }
    setEffectiveDate(grossPremiumList[0].effectiveDate);
    setOtherFees(grossPremiumList[0].fees == null ? 0 : grossPremiumList[0].fees);
    setOtherTaxes(grossPremiumList[0].taxes == null ? 0 : grossPremiumList[0].taxes);
    setDownPayment(grossPremiumList[0].downPayment == null ? 0 : grossPremiumList[0].downPayment.replace(",", ""));
    setFinancedAmount(grossPremiumList[0].amountFinanced == null ? 0 : grossPremiumList[0].amountFinanced.replace(",", ""));
    setPremium(grossPremiumList[0].premium == null ? 0 : grossPremiumList[0].premium.replace(",", ""));
    setTerm(grossPremiumList[0].term);
    setLineOfBusiness(grossPremiumList[0].lineOfBusiness);
    setCarrierName(grossPremiumList[0].carrierName);
    setIsFileUpload(true);
    setSubmissionId(submissionId);
    setUploadPdfUrl(response.data.fileurl);
    // if (response.data.insuredName.includes(",")) {
    //   setInsuredFirstName(response.data.insuredName.split(",")[1]);
    //   setInsuredLastName(response.data.insuredName.split(",")[0]);
    // } else {
    //   setInsuredFirstName(response.data.insuredName);
    //   setInsuredLastName("");
    // }
    setInsuredFirstName(response.data.insuredName);
    setQuoteNumber(response.data.quoteNumber == "" || response.data.quoteNumber == null ? "" : response.data.quoteNumber.split(".")[0]);
    setEffectiveAPR(response.data.effectiveAPR);
    if (response.data.brokerFee !== undefined) {
      setBrokerFee(
        response.data.brokerFee === "" || response.data.brokerFee === null ? "0" : response.data.brokerFee.replace("$", "").replace(",", "")
      );
    }
  };

  const IPFSResponseDataMap = (response: any) => {
    let submissionId = response.data.submissionId;
    let grossPremiumList = response.data.grossPremium;
    setGrossPremiumList(grossPremiumList);
    let premiums: any = [];
    let fees: any = [];
    let taxes: any = [];
    let downPayment: any = [];
    let financedAmount: any = [];
    let policyNumber: any = [];
    let term: any = [];
    let carrierName: any = [];
    let lineOfBusiness: any = [];
    let effectiveDate: any = [];
    grossPremiumList.forEach((item: any, index: any) => {
      premiums.push(item.premium == null ? 0 : item.premium.replace(",", ""));
      fees.push(item.fee == null ? 0 : item.fees.replace(",", ""));
      taxes.push(item.taxes == null ? 0 : item.taxes.replace(",", ""));
      downPayment.push(item.downPayment == null ? 0 : item.downPayment.replace(",", ""));
      financedAmount.push(item.amountFinanced == null ? 0 : item.amountFinanced.replace(",", ""));
      policyNumber.push(item.policyNumber);
      term.push(item.term);
      carrierName.push(item.carrierName);
      lineOfBusiness.push(item.lineOfBusiness);
      effectiveDate.push(item.effectiveDate);
    });
    setAddressLine1(response.data.addressLine1);
    setCity(response.data.city.replace(",", ""));
    setZipCode(response.data.zipCode);
    setState(response.data.state);
    if (props.policyDetails != "Create Policy") {
      setPolicyNumber(props.existingPolicyId);
    } else {
      setPolicyNumber(grossPremiumList[0].policyNumber);
    }
    setEffectiveDate(grossPremiumList[0].effectiveDate);
    setOtherFees(grossPremiumList[0].fees == null ? 0 : grossPremiumList[0].fees);
    setOtherTaxes(grossPremiumList[0].taxes == null ? 0 : grossPremiumList[0].taxes);
    setDownPayment(grossPremiumList[0].downPayment == null ? 0 : grossPremiumList[0].downPayment.replace(",", ""));
    setFinancedAmount(grossPremiumList[0].amountFinanced == null ? 0 : grossPremiumList[0].amountFinanced.replace(",", ""));
    setPremium(grossPremiumList[0].premium == null ? 0 : grossPremiumList[0].premium.replace(",", ""));
    setTerm(grossPremiumList[0].term);
    setLineOfBusiness(grossPremiumList[0].lineOfBusiness);
    setCarrierName(grossPremiumList[0].carrierName);
    setIsFileUpload(true);
    setSubmissionId(submissionId);
    setUploadPdfUrl(response.data.fileurl);
    // if (response.data.insuredName.includes(",")) {
    //   setInsuredFirstName(response.data.insuredName.split(",")[1]);
    //   setInsuredLastName(response.data.insuredName.split(",")[0]);
    // } else {
    //   setInsuredFirstName(response.data.insuredName);
    //   setInsuredLastName("");
    // }
    setInsuredFirstName(response.data.insuredName);
    setQuoteNumber(response.data.quoteNumber == "" || response.data.quoteNumber == null ? "" : response.data.quoteNumber.split(".")[0]);
    setEffectiveAPR(response.data.effectiveAPR);
    if (response.data.brokerFee !== undefined) {
      setBrokerFee(
        response.data.brokerFee === "" || response.data.brokerFee === null ? "0" : response.data.brokerFee.replace("$", "").replace(",", "")
      );
    }
  };

  const getNowCertsClients = (query: string) => {
    var data = {
      SearchValue: query,
    };
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/customerlist?searchText=${query}&nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        setSearchClientList(response.data);
        setSearchClientLoading(false);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while loading the insureds data.Please try again later.",
              life: 3000,
            });
          }
        }
        // setErrorMessage(errorMessage);
        setSearchClientLoading(false);
      });
  };

  const onClientSelect = (client: any) => {
    let insuredDatabaseId = client.databaseId;
    setSearch("");
    setSelectedClientName(client.name);
    setClientName(client.name);
    setInsuredDatabaseId(insuredDatabaseId);
    // getNowCertsPoliciesList(insuredDatabaseId);
  };

  const getNowCertsPoliciesList = (insuredId: string) => {
    setPolicyLoading(true);
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/insuredpolicies?insuredId=${insuredId}&nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.length > 0) {
          setPolicyLoading(false);
          setInsuredNoPolicyError("");
          setNowCertPolicyData(response.data);
          setSelectedPolicyIds(response.data[0].databaseId);
        } else {
          setNowCertPolicyData([]);
          setPolicyLoading(false);
          setInsuredNoPolicyError("Insured does not have policy.");
        }
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the insureds data.Please try again later.";
          }
        }
        setPolicyLoading(false);
      });
  };

  const onSelectPolicies = (rowData: any, options: any) => {
    return (
      <div className="checkbox">
        <label>
          <input
            type="checkbox"
            value=""
            checked={selectedPolicyIds === rowData.databaseId ? true : false}
            onChange={() => setSelectedPolicyIds(rowData.databaseId)}
            className="show-cursor bundle-checkbox"
          />
        </label>
      </div>
    );
  };
  const onQuoteChange = (e: any) => {
    setQuoteNumber(e.target.value);
  };
  const onInsuredFirstNameChange = (e: any) => {
    setInsuredFirstName(e.target.value);
  };
  const onInsuredLastNameChange = (e: any) => {
    setInsuredLastName(e.target.value);
  };
  const onPremiumChange = (e: any, index: any) => {
    setPremium(e.target.value);
  };

  const onDownPaymentChange = (e: any, index: any) => {
    setDownPayment(e.target.value);
  };
  const onFinancedAmountChange = (e: any, index: any) => {
    setFinancedAmount(e.target.value);
  };
  const onEffectiveAprChange = (e: any) => {
    setEffectiveAPR(e.target.value);
  };
  const onOtherTaxesChange = (e: any, index: any) => {
    const inputValue = e.target.value;
    const regex = /^[0-9]+(\.[0-9]{0,2})?$/;
    if (regex.test(inputValue) || inputValue === "") {
      setOtherTaxes(e.target.value);
    }
  };

  const onOtherFeesChange = (e: any, index: any) => {
    const inputValue = e.target.value;
    const regex = /^[0-9]+(\.[0-9]{0,2})?$/;
    if (regex.test(inputValue) || inputValue === "") {
      setOtherFees(e.target.value);
    }
  };
  const onBrokerFeeChange = (e: any) => {
    setBrokerFee(e.target.value);
  };

  const onAddressLine1Change = (e: any) => {
    setAddressLine1(e.target.value);
  };

  const onCityChange = (e: any) => {
    setCity(e.target.value);
  };

  const onZipCodeChange = (e: any) => {
    setZipCode(e.target.value);
  };

  const onStateChange = (e: any) => {
    setState(e.target.value);
  };
  const onPolicyChange = (e: any) => {
    if (props.policyDetails != "Create Policy") {
      return;
    } else {
      setPolicyNumber(e.target.value);
    }
  };
  const onEffectiveDateChange = (e: any) => {
    setEffectiveDate(e.target.value);
  };

  const onTermChange = (e: any) => {
    if (/^\d*$/.test(e.target.value)) {
      setTerm(e.target.value);
    }
  };
  const onCarrierChanges = (e: any) => {
    setCarrierName(e.target.value);
  };
  const onCoverageCodeChange = (e: any) => {
    setLineOfBusiness(e.target.value);
  };
  const validateInsuredDetails = () => {
    setPremiumErrorMessage([""]);
    let isValid = true;
    const errorValue = [...premiumErrorMessage];
    grossPremiumList.map((item: any, index: any) => {
      if (item.premium.length === 0) {
        isValid = false;
        errorValue[index] = "Total premium is required.";
        setPremiumErrorMessage(errorValue);
        return isValid;
      }
    });
    return isValid;
  };

  const getPolicyEndorsementFeeData = () => {
    let policyEndorsementFee: any = [];
    grossPremiumList.map((item: any, index: any) => {
      {
        let formattedPremium = item.premium.replace("$", "").replace(",", "");
        // let formattedDownPayment = item.downPayment.replace("$", "").replace(",", "");
        // let formattedFinancedAmount = item.amountFinanced.replace("$", "").replace(",", "")
        policyEndorsementFee.push({
          order: index,
          policyId: selectedPolicyIds,
          amount: parseFloat(formattedPremium),
          date: new Date().toJSON(),
          premiumType: 0,
          billingType: 4,
          type: 0,
          followUpDate: new Date().toJSON(),
        });
      }
    });

    if (
      (otherFees.length > 0 && otherFees !== null && otherFees !== undefined) ||
      (brokerFee !== "0" && brokerFee !== null && brokerFee !== undefined)
    ) {
      grossPremiumList.map((item: any, index: any) => {
        const formattedOtherFees = parseFloat(item.fees.replace("$", "").replace(",", ""));
        const formattedBrokerFee = parseFloat(brokerFee.replace("$", "").replace(",", ""));
        policyEndorsementFee.push({
          order: index,
          policyId: selectedPolicyIds,
          amount: formattedOtherFees + formattedBrokerFee,
          date: new Date().toJSON(),
          premiumType: 1,
          type: 3,
          followUpDate: new Date().toJSON(),
        });
      });
    }
    if (otherFees.length > 0 && otherTaxes !== null && otherTaxes !== undefined) {
      grossPremiumList.map((item: any, index: any) => {
        const formattedOtherTaxes = parseFloat(item.taxes.replace("$", "").replace(",", ""));
        policyEndorsementFee.push({
          order: index,
          policyId: selectedPolicyIds,
          amount: formattedOtherTaxes,
          date: new Date().toJSON(),
          premiumType: 1,
          type: 4,
          followUpDate: new Date().toJSON(),
        });
      });
    }
    return policyEndorsementFee;
  };

  const handleCancelClick = () => {
    setNowCertPolicyData([]);
    setGrossPremiumList([]);
    setSearch("");
    setClientName("");
    setIsFileUpload(false);
    setUploadPdfUrl("");
    setQuoteNumber("");
    setPremium("");
    setDownPayment("");
    setFinancedAmount("");
    setEffectiveAPR("");
    setOtherTaxes("");
    setOtherFees("");
    setSelectedPolicyIds("");
    setBrokerFee("0");
    removeFileFromList();
    setInsuredFirstName("");
    setInsuredLastName("");
    setAddressLine1("");
    setCity("");
    setState("");
    setZipCode("");
    setPolicyNumber("");
    setEffectiveDate("");
    setCarrierName("");
  };

  const uploadAgain = () => {
    setNowCertPolicyData([]);
    setGrossPremiumList([]);
    setSearch("");
    setClientName("");
    setIsFileUpload(false);
    setUploadPdfUrl("");
    setQuoteNumber("");
    setPremium("");
    setDownPayment("");
    setFinancedAmount("");
    setEffectiveAPR("");
    setOtherTaxes("");
    setLineOfBusiness("");
    setTerm("");
    setOtherFees("");
    setSelectedPolicyIds("");
    setBrokerFee("0");
    removeFileFromList();
    setUploadAgain(false);
    setInsuredFirstName("");
    setInsuredLastName("");
    setAddressLine1("");
    setCity("");
    setState("");
    setZipCode("");
    setPolicyNumber("");
    setEffectiveDate("");
    setCarrierName("");
  };

  const onSubPolicyDetailsSelect = (value: any) => {
    let indexValue = value;
    setSelectedInsuredDetails(indexValue);
  };

  const createNewInsured = async () => {
    try {
      // setSubmitLoading(true);
      var token = CommonValues.GetToken();
      var ncToken = CommonValues.GetNCToken();
      var data = JSON.stringify({
        commercialName: insuredFirstName,
        firstName: "",
        lastName: "",
        dba: "",
        addressLine1: addressLine1,
        addressLine2: "",
        state: state,
        city: city,
        zipCode: zipCode,
        eMail: "",
        cellPhone: insuredPhoneNumber,
        fein: "",
      });
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/insertinsured?submissionId=${submissionId}&nowCertsToken=${ncToken}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios(config);
      let insuredInfo = response.data.database_id;
      setNcURL(response.data.newInsuredLunchUrl);
      toast.current.show({
        severity: "success",
        detail: "Insured has been created successfully in NowCerts",
        life: 3000,
      });
      // setSubmitLoading(false);
      // setUploadAgain(true);
      return insuredInfo;
    } catch (error: any) {
      console.log(error);
      let errorMessage = "";
      if (error.response != null) {
        if (error.response.status == 401) {
          navigate("/");
          CommonValues.Logout(navigate);
        } else if (error.response.status == 400) {
          errorMessage = error.response.errors;
        } else {
          toast.current.show({
            severity: "error",
            detail: "Please correct NowCerts username and password",
            life: 3000,
          });
        }
      } else {
        toast.current.show({
          severity: "error",
          detail: "Unknown error while creating insured inside the NowCerts",
          life: 3000,
        });
      }
      setSubmitLoading(false);
      let insuredInfo = "";
      return insuredInfo;
    }
  };
  const createExpiryDate = () => {
    const result = new Date(effectiveDate);
    result.setMonth(result.getMonth() + parseInt(term));
    return result;
  };
  const createNewPolicy = async (insuredDataBaseId: any) => {
    const expirationDate = createExpiryDate();
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    var data = {
      insuredDatabaseId: insuredDataBaseId,
      firstName: insuredFirstName,
      lastName: insuredLastName,
      number: policyNumber,
      effectiveDate: effectiveDate,
      expirationDate: moment(expirationDate).format("MM/DD/YYYY"),
      bindDate: "",
      businessType: 0,
      billingType: 0,
      mgaName: "",
      carrierName: carrierName,
      premium: premium,
      lineOfBusinessName: lineOfBusiness,
      agencyFee: parseFloat(otherFees),
      taxes: parseFloat(otherTaxes),
    };
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/addpolicy?nowCertsToken=${nowCertsToken}&submissionId=${submissionId}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        toast.current.show({
          severity: "success",
          detail: "Policy has been created successfully in NowCerts",
          life: 3000,
        });
        setUploadAgain(true);
        setSubmitLoading(false);
        setTimeout(() => {
          uploadAgain();
        }, 100);
      })
      .catch((error) => {
        let errorMessage = "";
        setSubmitLoading(false);

        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while adding new policy",
              life: 3000,
            });
          }
        }
      });
  };
  const checkValidation = () => {
    let formIsValid = true;
    setInsuredFirstNameError("");
    setPolicyNumberError("");
    setPremiumAmountError("");
    setInsuredLastNameError("");
    setZipCodeError("");
    setStateCodeError("");
    setCoverageCodeError("");
    setTermError("");
    setEffectiveDateError("");
    if (insuredFirstName == "" || insuredFirstName == null) {
      setInsuredFirstNameError("Please enter first name");
      formIsValid = false;
    } else {
      setInsuredFirstNameError("");
    }
    // if (insuredLastName == "" || insuredLastName == null) {
    //   setInsuredLastNameError("Please enter last name");
    //   formIsValid = false;
    // } else {
    //   setInsuredLastNameError("");
    // }

    if (policyNumber == "" || policyNumber == null) {
      setPolicyNumberError("Please enter policy number");
      formIsValid = false;
    } else {
      setPolicyNumberError("");
    }
    if (premium.length <= 0) {
      setPremiumAmountError("Please enter premium amount");
      formIsValid = false;
    } else {
      setPremiumAmountError("");
    }
    if (effectiveDate == "" || effectiveDate == null) {
      setEffectiveDateError("Please select effective date");
      formIsValid = false;
    } else {
      setEffectiveDateError("");
    }
    if (term == "" || term == null) {
      setTermError("Please enter term");
      formIsValid = false;
    } else {
      setTermError("");
    }

    if (zipCode == "" || zipCode == null) {
      setZipCodeError("Please enter zip code");
      formIsValid = false;
    } else {
      setZipCodeError("");
    }
    if (state == "" || state == null) {
      setStateCodeError("Please select state");
      formIsValid = false;
    } else {
      setStateCodeError("");
    }
    if (lineOfBusiness == "" || lineOfBusiness == null) {
      setCoverageCodeError("Please enter coverage code");
      formIsValid = false;
    } else {
      setCoverageCodeError("");
    }

    return formIsValid;
  };
  const onSubmitClickCheckIfPolicyNoExist = (e: any) => {
    if (props.nowCertPolicyData.some((policy: any) => policy.policyNumber === policyNumber)) {
      setShowPolicyExistMessage(true);
      policyMessageRef.current?.toggle(e);
    }
    else {
      createNewPolicy(props.insuredId);
    }
  }
  const onSubmitOkClick = () => {
    setSubmitLoading(true);
    if (policyMessageRef.current) {
      policyMessageRef.current.hide();
    }
    createNewPolicy(props.insuredId);

  }
  const onSubmitCancelClick = () => {
    if (policyMessageRef.current) {
      policyMessageRef.current.hide();
    }
  }
  const onSubmitClick = async (e: any) => {
    if (checkValidation()) {
      if (createInsured && !createPolicy) {
        setSubmitLoading(true);
        createNewInsured();
      } else if (!createInsured && createPolicy) {
        onSubmitClickCheckIfPolicyNoExist(e);
      } else if (createInsured && createPolicy) {
        setSubmitLoading(true);
        const insuredDatabaseId: any = await createNewInsured();
        setTimeout(() => createNewPolicy(insuredDatabaseId));
      } else if (!createInsured && !createPolicy) {
        setSubmitLoading(true);
        createNewPolicy(props.insuredId);
      } else {
        toast.current.show({
          severity: "warn",
          detail: "Select any one of the processes: Create Client / Create Policy",
          life: 3000,
        });
      }
    }
    // if (createInsured !== false && createPolicy == false) {
    //   createNewInsured();
    // } else if (createPolicy !== false && createInsured == false) {
    // } else if (createInsured !== false && createPolicy !== false) {
    //   const insuredDatabaseId: any = await createNewInsured();
    //   setTimeout(() => createNewPolicy(insuredDatabaseId));
    // } else if (createInsured != true && createPolicy != true) {
    //   toast.current.show({
    //     severity: "warn",
    //     detail: "Select any one of the process Create Prospect / Create Policy",
    //     life: 3000,
    //   });
    // }
  };

  const onOpenClick = () => {
    window.open(ncURL, "_blank");
  };

  const onShowFeedbackPopup = (e: any) => {
    feedbackRef.current?.toggle(e);
  };

  const onFeedbackChange = (newFeedback: string) => {
    const feedback = { ...feedBackData, feedback: newFeedback, submissionId: submissionId };
    setFeedBackData(feedback);
    if (newFeedback.length == 0 || newFeedback.trim() === "") {
      setFeedBackError("Please enter your feedback");
    } else if (newFeedback.length > 0) {
      setFeedBackError("");
    }
  };

  const onThumbsUpClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === true) {
      liked = null;
    } else {
      liked = true;
    }
    rowData = { ...rowData, isLiked: liked, submissionId: submissionId };
    setFeedBackData(rowData);
    onSaveRatings(rowData);
  };

  const onThumbDownClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === false) {
      liked = null;
    } else {
      liked = false;
    }
    rowData = { ...rowData, isLiked: liked, submissionId: submissionId };
    setFeedBackData(rowData);
    onSaveRatings(rowData);
  };
  const onHideFeedbackPopup = () => {
    if (feedbackRef.current) {
      feedbackRef.current.hide();
      setFeedBackData([]);
    }
  };

  const onSubmitFeedbackClick = (rowData: any) => {
    if (rowData.feedback == null || rowData.feedback == "") {
      setFeedBackError("Please enter your feedback");
    } else {
      setFeedBackError("");
      setFeedbackLoading(true);
      setSubmissionId(submissionId);
      onSaveFeedback(rowData);
    }
  };

  const onSaveFeedback = (rowData: any) => {
    const token = CommonValues.GetToken();
    const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
    let data = {
      TransactionId: submissionId,
      Feedback: feedbackVal,
    };

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/pfscan/feedback`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({ severity: "success", detail: "Feedback Submitted successfully", life: 3000 });
        const feedback = { feedback: "", isLiked: null };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);
        setFeedbackLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while submitting feedback", life: 3000 });
          }
        }
      });
  };

  const onSaveRatings = (rowData: any) => {
    const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
    const token = CommonValues.GetToken();
    let data = {
      TransactionId: submissionId,
      IsLiked: rowData.isLiked,
      Feedback: feedbackVal
    };
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/pfscan/isliked`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({ severity: "success", detail: "Ratings Submitted successfully", life: 3000 });
        const feedback = { feedback: "", isLiked: rowData.isLiked };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);
        setFeedbackLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while submitting ratings", life: 3000 });
          }
        }
      });
  };
  const OnChangeInsuranceCompany = (value: any) => {
    if (value == "Select Insurance Company") {
      setInsuranceCompanyName("");
    } else {
      setInsuranceCompanyName(value);
    }
  };
  return (
    <>
      <Toast ref={toast} />
      <div className="row mt-3 d-flex align-items-center p-2">
        <div className="col-md-8 d-flex  align-items-center">
          <span>
            {" "}
            <AtkButton label="Back" onClick={props.onBackClick} className="policy-back-btn w-auto  me-3" />
          </span>
          <span className=" me-3">
            <label>
              <b>Client: </b> &nbsp;
            </label>
            {props.prospectDetails}
          </span>
          <span>
            <label>
              <b>Policy: </b> &nbsp;
            </label>
            {props.policyDetails}
          </span>
        </div>
        <div className="col-md-4 ">
          <div className="row pf-scan-balance d-flex justify-content-end mx-auto">
            <div className="col-6 text-center balance-span">
              <label>Current Balance</label>
              {currentBalanceLoading ? (
                <span className="balance-spinner d-flex  align-items-center justify-content-center ">
                  <AtkLoader />
                </span>
              ) : (
                <span className=" d-block w-auto ">${currentBalance}</span>
              )}
              <small className="d-block w-auto text-danger mt-0">25 cent will be charged per usage</small>
            </div>
          </div>
        </div>
      </div>
      <div className="row main-content-row mx-auto ">
        <div className="col-lg-10 col-md-12 col-sm-12 main-content-col" style={{ width: "100%" }}>
          <div className="row m-3">
            {isFileUpload ? (
              <div className="col">
                <iframe
                  src={uploadPdfUrl}
                  style={{
                    overflow: "scroll",
                    height: "100%",
                    width: "100%",
                  }}
                ></iframe>
              </div>
            ) : (
              <>
                <div
                  className="col uploadNowcertsDoc25 me-3 "
                  onDrop={(e) => {
                    onDropHandler(e);
                  }}
                  onDragOver={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="row w-100 ">
                    <div className="col-12 smart-scan-dropdown pb-0">
                      <select className="smart-scan-dropdown" onChange={(e) => OnChangeInsuranceCompany(e.target.value)}>
                        <option>Select Insurance Company</option>
                        {options1.map((item: any) => (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-10 p-0 my-1 mb-5 client-search-input-col position-relative mx-auto">
                      {search.length > 0 && (
                        <div id="client-search" className=" position-absolute  ">
                          {searchClientLoading ? (
                            <div className="d-flex justify-content-center my-2 client-loader-div">
                              <AtkLoader />
                            </div>
                          ) : searchClientList.length == 0 ? (
                            <div
                              style={{
                                padding: "5px",
                                background: "white",
                              }}
                            >
                              <span
                                style={{
                                  color: "#ddd",
                                  marginLeft: "10px",
                                }}
                              >
                                No results
                              </span>
                            </div>
                          ) : (
                            searchClientList.map((item: any) => (
                              <div
                                style={{
                                  cursor: "pointer",
                                  width: "100%",
                                }}
                                onClick={() => {
                                  onClientSelect(item);
                                }}
                              >
                                <a
                                  href="#"
                                  style={{
                                    color: "black",
                                    width: "100%",
                                    fontSize: "12px",
                                  }}
                                >
                                  {item.name}
                                </a>
                              </div>
                            ))
                          )}
                        </div>
                      )}
                    </div>
                    {uploadFile.length > 0 ? (
                      <div className="col-12">
                        {uploadFile.map((file: any, index: any) => (
                          <div className="show-pdf-icon">
                            <div className="file-name">
                              <div className="preview-icon">
                                <img src={PreviewIcon} />
                              </div>
                              <div>
                                <div title={fileNameTitle}>{fileName}</div>
                                <div>
                                  {(parseInt(file.size) / 1000).toFixed(0)}
                                  KB
                                </div>
                              </div>
                            </div>
                            {uploadComplete ? (
                              <div>
                                <span className="uploaded">Uploaded</span>
                              </div>
                            ) : (
                              uploadingFiles == false && (
                                <div onClick={() => removeFileFromList()} className="show-cursor">
                                  <img src={RemoveIcon} />
                                </div>
                              )
                            )}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="row" style={{ textAlign: "center" }}>
                        <div className="col-12">
                          <img src={PfdIcon} alt="" width="70" />
                        </div>

                        <div className="col-12 mt-4">
                          <div className="col-12">
                            <span className="pf-scan-darg-and-drop">Drag & Drop PremFi Agreement here</span>
                          </div>
                          <div className="col-12 my-2">
                            <span
                              style={{
                                color: "black",
                                fontSize: "large",
                              }}
                            >
                              or
                            </span>
                          </div>
                          <label className="mb-0 show-cursor">
                            <span className="browse">Browse PDF</span>
                            <input
                              type="file"
                              name="filefield"
                              accept=".pdf"
                              id="bulkFileUploader"
                              style={{
                                visibility: "hidden",
                                display: "none",
                              }}
                              onChange={(e) => {
                                onFileSelectHandler(e.target.files);
                              }}
                            />
                          </label>
                        </div>
                        <div className="coi-info">
                          <span className="text-14 mt-2 info">Supported document types: Capital premium finance and IPFS.</span>
                        </div>
                        <div className="col-12  text-12 mt-3">
                          <AtkMessage
                            appearance="information"
                            messageText={
                              "This tool works best with readable PDFs. Please note that scanned or image-based PDFs may not be processed. For scanned documents, consider converting them to a readable format using OCR tools before uploading."
                            }
                          />
                        </div>
                      </div>
                    )}

                    <div className="col-12 text-center">
                      {isfileuploaded ? null : (
                        <>
                          {uploadingFiles == false && incompleteRequestData == false && uploadingRetry == false && uploadFile.length > 0 && (
                            <div
                              className="d-flex justify-content-center"
                              style={{
                                width: "100%",
                                padding: "20px",
                              }}
                            >
                              {parseFloat(currentBalance) > 0 ? (
                                <>
                                  {loading ? (
                                    <div className="d-flex justify-content-center align-items-center p-2">
                                      <AtkLoader></AtkLoader>
                                    </div>
                                  ) : (
                                    <AtkButtonIconBefore
                                      label="Upload"
                                      className="pf-secondary-btn"
                                      onClick={() => {
                                        createUploadRequest();
                                      }}
                                    />
                                  )}
                                </>
                              ) : (
                                <span className="text-danger text-12 ">You don't have enough credits to upload pdf.</span>
                              )}
                            </div>
                          )}
                        </>
                      )}

                      {errorMessage.length > 0 ? (
                        <div
                          className="d-flex justify-content-center Acord25-upload-file-error"
                          style={{
                            width: "100%",
                            padding: "20px 20px 0px",
                          }}
                        >
                          <AtkMessage appearance="error" messageText={errorMessage} />
                        </div>
                      ) : null}
                      {successMessage.length > 0 ? (
                        <div
                          className="d-flex justify-content-center upload-file-error"
                          style={{
                            width: "100%",
                            padding: "20px 20px 0px",
                          }}
                        >
                          <AtkMessage appearance="success" messageText={successMessage} />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </>
            )}
            <div
              className="col p-2 ms-2"
              style={{
                border: "1px solid darkgrey",
                borderRadius: "30px",
                minHeight: "66vh",
              }}
            >
              <div className="col smartscan-tab">
                <span className="policy-name-span show-cursor">Details</span>
              </div>
              <NewInsuredPage
                quoteNumber={quoteNumber}
                premiumAmount={premium}
                fees={otherFees}
                taxes={otherTaxes}
                downPayment={downPayment}
                financedAmount={financedAmount}
                effectiveAPR={effectiveAPR}
                brokerFee={brokerFee}
                onQuoteChange={onQuoteChange}
                onPremiumChange={onPremiumChange}
                onDownPaymentChange={onDownPaymentChange}
                onEffectiveAprChange={onEffectiveAprChange}
                onOtherTaxesChange={onOtherTaxesChange}
                onOtherFeesChange={onOtherFeesChange}
                onBrokerFeeChange={onBrokerFeeChange}
                onFinancedAmountChange={onFinancedAmountChange}
                onInsuredFirstNameChange={onInsuredFirstNameChange}
                onInsuredLastNameChange={onInsuredLastNameChange}
                onAddressLine1Change={onAddressLine1Change}
                onCityChange={onCityChange}
                onStateChange={onStateChange}
                onZipCodeChange={onZipCodeChange}
                onPolicyNumberChange={onPolicyChange}
                onEffectiveDateChange={onEffectiveDateChange}
                onTermChange={onTermChange}
                onCarrierChanges={onCarrierChanges}
                onCoverageCodeChange={onCoverageCodeChange}
                lineOfBusiness={lineOfBusiness}
                premiumErrorMessage={premiumErrorMessage}
                addressLine1={addressLine1}
                city={city}
                state={state}
                zipCode={zipCode}
                policyNumber={policyNumber}
                effectiveDate={effectiveDate}
                term={term}
                insuredFirstName={insuredFirstName}
                insuredLastName={insuredLastName}
                carrierName={carrierName}
                insuredFirstNameError={insuredFirstNameError}
                insuredLastNameError={insuredLastNameError}
                policyNumberError={policyNumberError}
                effectiveDateError={effectiveDateError}
                termError={termError}
                premiumAmountError={premiumAmountError}
                zipCodeError={zipCodeError}
                stateCodeError={stateCodeError}
                coverageCodeError={coverageCodeError}
              ></NewInsuredPage>

              {insuredNoPolicyError.length !== 0 ? <AtkMessage messageText={insuredNoPolicyError}></AtkMessage> : null}

              <div className="row">
                <div className="col-12 d-flex align-items-center  justify-content-end my-2">
                  {uploadagain ? (
                    <>
                      {ncURL != "" && ncURL != null ? (
                        <>
                          <AtkButtonIconBefore
                            label="Open Insured"
                            onClick={onOpenClick}
                            className="pf-secondary-btn w-auto me-2 "
                            style={{ marginTop: "7px" }}
                          />
                        </>) : null
                      }
                      <AtkButtonIconBefore
                        label="Feedback"
                        onClick={(e: any) => onShowFeedbackPopup(e)}
                        className="pf-secondary-btn w-auto me-2 "
                        style={{ marginTop: "7px" }}
                      />
                      <AtkButtonIconBefore label="Upload again" onClick={uploadAgain} className="pf-secondary-btn w-auto me-2" style={{ marginTop: "7px" }} />
                    </>
                  ) : (
                    <>
                      {isFileUpload ? (
                        <>
                          {submitLoading ? (
                            <div className="d-flex justify-content-center align-items-center p-2">
                              <AtkLoader></AtkLoader>
                            </div>
                          ) : (
                            <Button onClick={(e: any) => onSubmitClick(e)} className="pf-secondary-btn w-auto me-2" shouldFitContainer>
                              Submit
                            </Button>
                          )}

                        </>
                      ) : null}
                    </>
                  )}

                  <OverlayPanel ref={feedbackRef} className="commission-feedback-panel feedback-panel">
                    <div className="row mx-auto d-flex align-items-center mb-2">
                      <div className="col-12 feedback-header">
                        <h4>Feedback</h4>
                      </div>
                      <div className={"form-group col-12 mb-2 text-12"}>
                        <textarea
                          name="feedback"
                          onChange={(e) => onFeedbackChange(e.target.value)}
                          value={feedBackData.feedback}
                          className={`form-control comment-text ${feedBackError.length > 0 ? " validation-div " : ""}`}
                          rows={4}
                          placeholder="Enter your feedback here"
                        />
                        <span className="text-danger  text-12 mt-1">{feedBackError}</span>
                      </div>
                      <div className="col-4 d-flex justify-content-evenly">
                        <i
                          className={`show-cursor fa fa-thumbs-up commission-feedback-icon 
 ${feedBackData.isLiked == true ? " active" : " "}`}
                          aria-hidden="true"
                          onClick={(e) => onThumbsUpClick(feedBackData)}
                        ></i>
                        <i
                          className={`show-cursor fa fa-thumbs-down commission-feedback-icon 
  ${feedBackData.isLiked == false ? " active" : " "}`}
                          aria-hidden="true"
                          onClick={(e) => onThumbDownClick(feedBackData)}
                        ></i>
                      </div>
                      <div className="col-4 text-end p-2 pe-0  ">
                        <AtkButton label="Cancel" onClick={onHideFeedbackPopup} className="pf-secondary-btn w-auto" />
                      </div>
                      <div className="col-4 text-end p-2  ">
                        {submissionId == feedBackData.submissionId && feedbackLoading ? (
                          <div className="d-flex justify-content-center align-items-center">
                            <AtkLoader />
                          </div>
                        ) : (
                          <AtkButton label="Submit" onClick={() => onSubmitFeedbackClick(feedBackData)} className="pf-secondary-btn w-auto " />
                        )}
                      </div>
                    </div>
                  </OverlayPanel>
                  <OverlayPanel ref={policyMessageRef} className="premfi-scan-confirm-msg-panel ">
                    <SectionMessage appearance="warning">
                      <p>
                        <span className=" d-block text-14 text-dark">A policy with the same policy number already exists, clicking "OK" will update the policy. Are you sure you want to continue?</span>
                      </p>
                      <div className="text-end mt-2">
                        <Button onClick={onSubmitOkClick} className="pf-secondary-btn w-auto me-2" shouldFitContainer>
                          OK
                        </Button>
                        <AtkButton label="Cancel" onClick={onSubmitCancelClick} className="pf-primary-btn w-auto" />
                      </div>
                    </SectionMessage>
                  </OverlayPanel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
