import { useEffect, useRef, useState } from "react";
import PfdIcon from "../../assets/images/file.png";
import PreviewIcon from "../../assets/images/paper.png";
import RemoveIcon from "../../assets/images/trashcan.png";
import AtkButton from "../../AtlaskitControls/AtkButton";
import AtkButtonIconBefore from "../../AtlaskitControls/AtkButtonIconBefore";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import InsuredDetails from "./InsuredDetails";
import CoverageLimitDetails from "./CoverageLimitDetails";
import DriverScheduleDetails from "./DriverScheduleDetails";
import ScheduledVehiclesDetails from "./ScheduledVehiclesDetails";
import CommonValues from "../../common/utils";
import axios from "axios";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import MTMScanOptionSelectPage from "./MTMScanOptionSelectPage";
import { OverlayPanel } from "primereact/overlaypanel";
import AtkMessage from "../../AtlaskitControls/AtkMessage";

export default function MTMScanFileUploadPage() {
    const toast: any = useRef("");
    const navigate = useNavigate();
    const feedbackRef = useRef<OverlayPanel>(null);
    const [uploadFile, setUploadFile] = useState<any>([]);
    const [uploadFileLimit, setUploadFileLimit] = useState(1);
    const [uploadingFiles, setUploadingFiles] = useState(false);
    const [uploadingRetry, setUploadingRetry] = useState(false);
    const [isfileuploaded, setIsfileuploaded] = useState(false);
    const [fileNameTitle, setFileNameTitle] = useState("");
    const [fileName, setFileName] = useState("");
    const [isFileUpload, setIsFileUpload] = useState(false);
    const [uploadPdfUrl, setUploadPdfUrl] = useState("");
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState("Insured");
    const [insuredName, setInsuredName] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [effectiveDate, setEffectiveDate] = useState("");
    const [expirationDate, setExpirationDate] = useState("");
    const [policyNumber, setPolicyNumber] = useState("");
    const [policyPremium, setPolicyPremium] = useState("");
    const [coveragelimitResponse, setCoverageLimitResponse] = useState<any>([]);
    const [scheduledVehicleResponse, setScheduledVehicleResponse] = useState<any>([]);
    const [driverScheduleResponse, setDriverScheduleResponse] = useState<any>([]);
    const [uploadComplete, setUploadComplete] = useState(false);
    const [showUploadAgain, setShowUploadAgain] = useState(false);
    const [ncURL, setNcURL] = useState("");
    const [submitLoading, setSubmitLoading] = useState(false);
    const [submissionId, setSubmissionId] = useState("");
    const [showMTMScanSelectOptionPage, setShowMTMSelectOptionPage] = useState(true);
    const [isCreateNewClientSelected, setIsCreateNewClientSelected] = useState(true);
    const [isExistingClientSelected, setIsExistingClientSelected] = useState(false);
    const [isCreateNewPolicySelected, setIsCreateNewPolicySelected] = useState(true);
    const [selectedOptionName, setSelectedOptionName] = useState("");
    const [search, setSearch] = useState("");
    const [searchClientList, setSearchClientList] = useState<any>([]);
    const [searchClientLoading, setSearchClientLoading] = useState(true);
    const [clientId, setClientId] = useState("");
    const [clientName, setClientName] = useState("");
    const [clientNameError, setClientNameError] = useState("");
    const [feedBackData, setFeedBackData] = useState<any>([]);
    const [feedBackError, setFeedBackError] = useState("");
    const [feedbackLoading, setFeedbackLoading] = useState(false);
    const [selectDocumentType, setSelectDocumentType] = useState("");

    const [insuredNameerrorMessage, setInsuredNameErrorMessage] = useState("");
    const [addressErrorMessage, setAddressErrorMessage] = useState("");
    const [cityErrorMessage, setCityErrorMessage] = useState("");
    const [sateErrorMessage, setStateErrorMessage] = useState("");
    const [zipCodeErrorMessage, setZipCodeErrorMessage] = useState("");
    const [policyNumberErrorMessage, setPolicyNumberErrorMessage] = useState("");
    const [currentBalanceLoading, setCurrentBalanceLoading] = useState(false);
    const [currentBalance, setCurrentBalance] = useState("0");

    let documentType = [
        { value: "Binder", label: "Binder" },
        { value: "Quote", label: "Quote" }
    ];


    useEffect(() => {
        const token = CommonValues.GetToken();
        if (token == "" || token == null) {
            CommonValues.Logout(navigate);
        }
        getCardDetails();
    }, []);

    useEffect(() => {
        if (search.replaceAll(" ", "") == "") {
            return;
        }
        setSearchClientLoading(true);
        if (CommonValues.GetTenantId() == process.env.REACT_APP_NowCerts_Tenant_Id) {
            const searchTimeout = setTimeout(() => {
                getNowCertsClients(search);
            }, 10);
            return () => clearTimeout(searchTimeout);
        }
    }, [search]);

    const getNowCertsClients = (query: string) => {
        var data = {
            SearchValue: query,
        };
        let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
        var config = {
            method: "GET",
            url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/customerlist?searchText=${query}&nowCertsToken=${nowCertsToken}`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then((response) => {
                setSearchClientList(response.data);
                setSearchClientLoading(false);
            })
            .catch((error) => {
                let errorMessage = "";
                if (error.response != null) {
                    if (error.response.status === 401) {
                        CommonValues.Logout(navigate);
                    } else {
                        // if (toast.current) {
                        //   toast.current.show({
                        //     severity: "error",
                        //     detail: "Unknown error while loading the insureds data.Please try again later",
                        //     life: 3000,
                        //   });
                        // }
                    }
                }
                setSearchClientLoading(false);
            });
    };

    const onClientSearchChange = (value: any) => {
        if (value.length > 255 || selectedOptionName !== "useExisting") {
            return;
        }
        setSearch(value);
    };

    const onClientSelect = (client: any) => {
        setSearch("");
        setClientName(client.name);
        setClientId(client.databaseId);
        setClientNameError("");
    };

    const handleTabClick = (tabName: any) => {
        setActiveTab(tabName);
    };
    const onChangesInsuredName = (value: any) => {
        setInsuredNameErrorMessage("")
        let inputValue = value.replace(/[^a-zA-Z0-9 ]/g, "");
        const Value = inputValue.slice(0, 255);
        setClientName(Value);
        setInsuredName(value);
    }
    const onChangesAddress = (value: any) => {
        let inputValue = value.replace(/[^a-zA-Z0-9 ]/g, "");
        const Value = inputValue.slice(0, 255);
        setAddress(value);
        setAddressErrorMessage("");

    }
    const onChangesCity = (value: any) => {
        let inputValue = value.replace(/[^a-zA-Z ]/g, "");
        const Value = inputValue.slice(0, 255);
        setCity(value);
        setCityErrorMessage("");
    }
    const onChangesState = (value: any) => {
        setStateErrorMessage("")
        setState(value);
    }
    const onChangesZipCode = (value: any) => {
        const re = /^[0-9\b]+$/;
        if (value === "" || re.test(value)) {
            if (value.length > 9) {
                return;
            }
            setZipCode(value)
        }
        setZipCodeErrorMessage("");
    }
    const onChangePolicyNumber = (value: any) => {
        setPolicyNumber(value);
    }
    const onChangesPolicyPremium = (value: any) => {
        setPolicyPremium(value);
    }
    const onChangeEffectiveDate = (value: any) => {
        setEffectiveDate(value);
    };
    const onChangeExpiryDate = (value: any) => {
        setExpirationDate(value)
    };
    const onDropHandler = (event: any) => {
        event.preventDefault();
        onFileSelectHandler(event.dataTransfer.files);
    };
    const OnChangeDocumentType = (value: any) => {
        if (value == "Select Document Type") {
            setSelectDocumentType("");
        } else {
            setSelectDocumentType(value);
        }
    };
    const createUploadRequest = () => {
        if (selectDocumentType.length == 0) {
            toast.current.show({
                severity: "error",
                detail: "Please select document type.",
                life: 3000,
            });
            setLoading(false);
            return;
        }
        setLoading(true);
        var FormData = require("form-data");
        var data = new FormData();
        data.append("uploadFile", uploadFile[0].file);
        data.append("documentType", selectDocumentType);
        let token = CommonValues.GetToken();

        var config = {
            method: "post",
            url: `${process.env.REACT_APP_DataFabric_Url}/api/MTMScan/mtmscan`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: data,
        };
        axios(config)
            .then((response: any) => {
                if (response.data.responseJson.length > 0) {
                    setSubmissionId(response.data.submissionId);
                    let driverScheduleResponse: any = [];
                    let coveragelimitResponse: any = [];
                    let scheduledVehicleResponse: any = [];
                    driverScheduleResponse = response.data.responseJson[0].driverSchedule;
                    if (response.data.responseJson[0].autoMobileLiability != null) {
                        coveragelimitResponse.push(response.data.responseJson[0].autoMobileLiability);
                    }
                    else {
                        setCoverageLimitResponse([])
                    }

                    scheduledVehicleResponse = response.data.responseJson[0].vehicles
                    setInsuredName(response.data.responseJson[0].namedInsured)
                    setAddress(response.data.responseJson[0].streetAddress)
                    setCity(response.data.responseJson[0].city)
                    setState(response.data.responseJson[0].state)
                    setZipCode(response.data.responseJson[0].zipCode)
                    setEffectiveDate(response.data.responseJson[0].effectiveDate)
                    if (response.data.responseJson[0].expirationDate != null) {
                        setExpirationDate(response.data.responseJson[0].expirationDate)
                    }
                    else {
                        setExpirationDate("");
                    }

                    setPolicyNumber(response.data.responseJson[0].policyNumber)
                    if (response.data.responseJson[0].policyPremium != null) {
                        setPolicyPremium(response.data.responseJson[0].policyPremium.replace(/[,$]/g, "").replace(/\.00$/, "").trim())
                    }
                    else {
                        setPolicyPremium("")
                    }

                    setDriverScheduleResponse(driverScheduleResponse)
                    setCoverageLimitResponse(coveragelimitResponse);
                    setScheduledVehicleResponse(scheduledVehicleResponse)
                    setLoading(false);
                    setIsFileUpload(true);
                    setUploadPdfUrl(response.data.fileUrl);
                }
                else if(response.data.status == "Failed" && response.data.errorMessage =="User does not have enough credits for MTM Scan API call." ) {
                    setLoading(false);
                    toast.current.show({
                        severity: "info",
                        detail: response.data.errorMessage,
                        life: 3000,
                    });
                }
                getCardDetails();
            })
            .catch((error: any) => {
                console.log(error);
                localStorage.removeItem("currentPage");
                if (error.response != null && error.response.status == 401) {
                    navigate("/");
                    CommonValues.Logout(navigate);
                } else {
                    toast.current.show({
                        severity: "error",
                        detail: "Error while creating the request.",
                        life: 3000,
                    });
                    setLoading(false);
                }
            });
    };
    const onFileSelectHandler = (files: any) => {
        setFileName("");
        setFileNameTitle("")
        const isValidated = validateSelectedFiles(files);
        if (!isValidated) {
            toast.current.show({
              severity: "warn",
              detail: "Please select PDF files only.",
              life: 3000,
            });
            return false;
          }
        let fileList = [];
        let totalSize = 0;
        let currentFiles = uploadFile;
        let filename = files[0].name;
        if (filename.length > 50) {
            let shortenString = filename.substring(0, 50).concat("...");
            setFileName(shortenString);
            setFileNameTitle(filename);
        } else {
            setFileName(filename);
            setFileNameTitle(filename);
        }
        for (let i = 0, len = files.length; i < len; i++) {
            fileList.push({
                file: files[i],
                name: files[i].name,
                size: files[i].size,
            });
            totalSize = totalSize + files[i].size;
        }

        if (fileList.length > 0) {
            if (currentFiles.length > 0) {
                for (var i = 0, l = currentFiles.length; i < l; i++) {
                    for (var j = 0; j < fileList.length; j++) {
                        if (currentFiles[i].name === fileList[j].name) {
                            fileList.splice(j, 1);
                        }
                    }
                }
            }
            let allFiles = currentFiles.concat(fileList);
            if (allFiles.length > uploadFileLimit) {
                allFiles = allFiles.slice(0, uploadFileLimit);
                toast.current.show({
                    severity: "error",
                    detail: "Multiple files cannot be uploaded at the same time; please upload a single file.",
                    life: 3000,
                });
            }
            setUploadFile(allFiles);
        }
    };

    const validateSelectedFiles = (files: any) => {
        var regularExpression = /(?:\.([^.]+))?$/;
        var isValid = true;
    
        for (let index = 0, len = files.length; index < len; index++) {
          const fileExtension: any = regularExpression.exec(files[index].name);
          if (fileExtension === undefined || fileExtension[0] == "") {
            isValid = false;
            break;
          }
          if (fileExtension?.[1].toLowerCase().includes("pdf")) {
            isValid = true;
          } else {
            isValid = false;
            break;
          }
        }
        return isValid;
      };

    const removeFileFromList = () => {
        setUploadFile([]);
        setIsfileuploaded(false);
    };
    const uploadAgain = () => {
        setActiveTab("Insured");
        setIsFileUpload(false);
        setUploadFile([]);
        setFileName("");
        setFileNameTitle("");
        setShowUploadAgain(false);
        setInsuredName("");
        setAddress("");
        setCity("");
        setState("");
        setZipCode("");
        setPolicyNumber("");
        setPolicyPremium("");
        setEffectiveDate("");
        setExpirationDate("");
        setCoverageLimitResponse([]);
        setDriverScheduleResponse([]);
        setScheduledVehicleResponse([]);
        setInsuredNameErrorMessage("");
        setAddressErrorMessage("");
        setCityErrorMessage("");
        setStateErrorMessage("");
        setZipCodeErrorMessage("");
        setPolicyNumberErrorMessage("");
    };

    const onSubmit = async () => {
        if (checkValidation()) {
            if (isExistingClientSelected != true) {
                const insuredDatabaseId: any = await createNewInsured(); 
            }
            else {
                await createNewPolicy(clientId,true);
            }
        }
    }
    const createNewInsured = async () => {
        setSubmitLoading(true)
        setNcURL("");
        try {
            var token = CommonValues.GetToken();
            var ncToken = CommonValues.GetNCToken();
            var data = JSON.stringify({
                commercialName: insuredName,
                firstName: "",
                lastName: "",
                dba: "",
                addressLine1: address,
                addressLine2: "",
                state: state,
                city: city,
                zipCode: zipCode,
                eMail: "",
                cellPhone: "",
                fein: "",
            });
            var config = {
                method: "post",
                url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/insertinsured?submissionId=${"hdtgfy-474dofi-854754f-ufifu"}&nowCertsToken=${ncToken}`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                data: data,
            };

            const response = await axios(config);
            let insuredInfo = response.data.database_id;
            setNcURL(response.data.newInsuredLunchUrl);
            createNewPolicy(response.data.database_id,false);
            toast.current.show({ severity: "success", detail: "Insured has been created successfully in NowCerts", life: 3000 });
            return insuredInfo;
        } catch (error: any) {
            setSubmitLoading(false)
            console.log(error);
            let errorMessage = "";
            if (error.response != null) {
                if (error.response.status == 401) {
                    navigate("/");
                    CommonValues.Logout(navigate);
                } else if (error.response.status == 400) {
                    toast.current.show({
                        severity: "error",
                        detail: error.response.errors,
                        life: 3000,
                    });
                } else {
                    toast.current.show({
                        severity: "error",
                        detail: "Please correct NowCerts username and password.",
                        life: 3000,
                    });
                }
            } else {
                toast.current.show({
                    severity: "error",
                    detail: "Unknown error while creating insured inside the NowCerts.",
                    life: 3000,
                });
            }
            let insuredInfo = "";
            return insuredInfo;
        }
    };
    const createNewPolicy = async (insuredDataBaseId: any,submitLoading:any) => {
        if(submitLoading== true){
            setSubmitLoading(true);
        }
        let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
        var data = {
            insuredDatabaseId: insuredDataBaseId,
            firstName: "",
            lastName: "",
            number: policyNumber,
            effectiveDate: effectiveDate,
            expirationDate: expirationDate,
            bindDate: "",
            businessType: 0,
            billingType: 0,
            mgaName: "",
            carrierName: "",
            premium: policyPremium,
            lineOfBusinessName: "",
        };
        var config = {
            method: "post",
            url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/addpolicy?nowCertsToken=${nowCertsToken}&submissionId=${submissionId}`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then((response) => {
                addVehicleDataInNowCert(insuredDataBaseId)
            })
            .catch((error) => {
                setSubmitLoading(false);
                let errorMessage = "";
                setLoading(false)
                if (error.response != null) {
                    if (error.response.status === 401) {
                        CommonValues.Logout(navigate);
                    } else {
                        toast.current.show({
                            severity: "error",
                            detail: "Unknown error while adding new policy",
                            life: 3000,
                        });
                    }
                }
            });
    };

    const addVehicleDataInNowCert = async (insuredDatabaseId: any) => {
        const requestDataList: any = [];
        scheduledVehicleResponse.map((item: any) => {
            requestDataList.push({
                insured_database_id: insuredDatabaseId,
                vinNumber: item.vin,
                vehicleManufacturer: item.make,
                vehicleType: "Truck Tractor",
                year: parseInt(item.year),
                insured_commercial_name: insuredName,
                check: true
            })
        })
        const data = JSON.stringify(requestDataList);

        let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
        const config = {
            method: "POST",
            url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/addvehicles?&ncToken=${nowCertsToken}`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then((response) => {
                if (coveragelimitResponse.length > 0) {
                    addCoverageLimit(response.data, insuredDatabaseId);
                }else{
                    addDriver(insuredDatabaseId);
                }

            })
            .catch((error) => {
                setSubmitLoading(false);
                let errorMessage = "";
                if (error.response != null) {
                    if (error.response.status === 401) {
                        CommonValues.Logout(navigate);
                    } else if (error.response.status === 400) {
                        toast.current.show({
                            severity: "error",
                            detail: "Bad Request",
                            life: 3000,
                        });
                    } else {
                        toast.current.show({
                            severity: "error",
                            detail: "Unknown error while adding vehicle data in nowcerts",
                            life: 3000,
                        });
                    }
                }
                //setSubmitLoading(false);
            });
    };

    const addCoverageLimit = async (vehicleResponse: any, insuredDatabaseId: any) => {
        let addVehicleCoverageRequest: any = [];
        const coverageLimit: any = [];
        if (selectDocumentType == "Binder") {
            coveragelimitResponse.map((item: any) => {
                coverageLimit.push({
                    bodilyinjurylimit: item.bodilyInjuryPropertyDamageLimit,
                    bodilyinjurypremium: "0",
                    propertydamagelimit: item.bodilyInjuryPropertyDamageLimit,
                    propertydamagepremium: "0",
                    umbilimit: item.umbiLimit,
                    umbipremium: "0",
                    uimbilimit: item.uimbiLimit,
                    uimbipremium: "0",
                    umpdlimit: item.umpdLimit,
                    umpdpremium: "0",
                    medicalpaymentslimit: item.medicalPaymentsLimit,
                    medicalpaymentspremium: "0",
                    piplimit: item.coveredAutosSymbolPIP,
                    pippremium: "0",
                })
            })
        }
        else {
            coveragelimitResponse.map((item: any) => {
                coverageLimit.push({
                    bodilyinjurylimit: "",
                    bodilyinjurypremium: "0",
                    propertydamagelimit: "",
                    propertydamagepremium: "0",
                    umbilimit: item.umbiLimit,
                    umbipremium: "0",
                    uimbilimit: item.uimbiLimit,
                    uimbipremium: "0",
                    umpdlimit: item.umpdLimit,
                    umpdpremium: "0",
                    medicalpaymentslimit: item.medicalPaymentsLimit,
                    medicalpaymentspremium: "0",
                    piplimit: "",
                    pippremium: "0",
                })
            })
        }
        addVehicleCoverageRequest = vehicleResponse.map((item: any) => {
            return {
                vehicleDatabaseId: item.databaseId,
                insureddatabaseid: insuredDatabaseId,
                ...coverageLimit[0]
            }
        })

        const data = JSON.stringify(addVehicleCoverageRequest)
        let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
        const config = {
            method: "POST",
            url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/addvehiclesCoverage?&ncToken=${nowCertsToken}`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then((response) => {
                addDriver(insuredDatabaseId)
            })
            .catch((error) => {
                setSubmitLoading(false);
                let errorMessage = "";
                if (error.response != null) {
                    if (error.response.status === 401) {
                        CommonValues.Logout(navigate);
                    } else if (error.response.status === 400) {
                        toast.current.show({
                            severity: "error",
                            detail: "Bad Request",
                            life: 3000,
                        });
                    } else {
                        toast.current.show({
                            severity: "error",
                            detail: "Unknown error while adding vehicle coverage in nowcerts",
                            life: 3000,
                        });
                    }
                }
            });
    }

    const addDriver = (insuredDatabaseId: any) => {
        let requestDataList: any = [];
        let name: any;
        driverScheduleResponse.map((item: any) => {
            if (item.name != null || item.name != undefined) {
                name = item.name.split(" ")
            }
            else {
                name = ""
            }
            requestDataList.push({
                insured_database_id: insuredDatabaseId,
                insured_commercial_name: insuredName,
                first_name: name[0] == undefined ? "" : name[0],
                last_name: name[0] == undefined ? "" : name[2] == undefined ? name[1] : name[2],
                license_number: item.licenseNumber,
                date_of_birth: item.dob,
                excluded: item.excluded == "N" ? false : true,
                active: true
            })
        })
        const data = JSON.stringify(requestDataList);

        let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
        const config = {
            method: "POST",
            url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/adddrivers?&ncToken=${nowCertsToken}`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then((response) => {
                updateMTMScan()
            })
            .catch((error) => {
                setSubmitLoading(false);
                let errorMessage = "";
                if (error.response != null) {
                    if (error.response.status === 401) {
                        CommonValues.Logout(navigate);
                    } else if (error.response.status === 400) {
                        toast.current.show({
                            severity: "error",
                            detail: "Bad Request",
                            life: 3000,
                        });
                    } else {
                        toast.current.show({
                            severity: "error",
                            detail: "Unknown error while adding driver data in nowcerts",
                            life: 3000,
                        });
                    }
                }
                //setSubmitLoading(false);
            });
    }

    const onFeedbackClick = (e: any) => {
        feedbackRef.current?.toggle(e);
    };

    const onFeedbackChange = (newFeedback: string) => {
        const feedback = { ...feedBackData, feedback: newFeedback, submissionId: submissionId };
        setFeedBackData(feedback);
        if (newFeedback.length == 0 || newFeedback.trim() === "") {
            setFeedBackError("Please enter your feedback");
        } else if (newFeedback.length > 0) {
            setFeedBackError("");
        }
    };
    const onThumbsUpClick = (rowData: any) => {
        let liked: any;
        if (rowData.isLiked === true) {
            liked = null;
        } else {
            liked = true;
        }
        rowData = { ...rowData, isLiked: liked, submissionId: submissionId };
        setFeedBackData(rowData);
        onSaveRatings(rowData);
    };
    const onThumbDownClick = (rowData: any) => {
        let liked: any;
        if (rowData.isLiked === false) {
            liked = null;
        } else {
            liked = false;
        }
        rowData = { ...rowData, isLiked: liked, submissionId: submissionId };
        setFeedBackData(rowData);
        onSaveRatings(rowData);
    };
    const onHideFeedbackPopup = () => {
        if (feedbackRef.current) {
            feedbackRef.current.hide();
            setFeedBackData([]);
        }
    };
    const onSubmitFeedbackClick = (rowData: any) => {
        if (rowData.feedback == null || rowData.feedback == "") {
            setFeedBackError("Please enter your feedback");
        } else {
            setFeedBackError("");
            onSaveFeedback(rowData);
            setFeedbackLoading(true);
        }
    };
    const onSaveRatings = (rowData: any) => {
        const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
        const token = CommonValues.GetToken();
        let data = {
            TransactionId: rowData.submissionId,
            Feedback: feedbackVal,
            IsLiked: rowData.isLiked,
        };
        let config = {
            method: "post",
            url: `${process.env.REACT_APP_DataFabric_Url}/api/MTMScan/isliked`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: data,
        };

        axios
            .request(config)
            .then((response) => {
                toast.current.show({ severity: "success", detail: "Ratings Submitted successfully", life: 3000 });
                const feedback = { feedback: "", isLiked: rowData.isLiked };
                setFeedbackLoading(false);
                setFeedBackData(feedback);
                onHideFeedbackPopup();
            })
            .catch((error: any) => {
                console.log(error);
                setFeedbackLoading(false);
                if (error.response != null) {
                    if (error.response.status === 401) {
                        CommonValues.Logout(navigate);
                    } else {
                        toast.current.show({ severity: "error", detail: "Unknown error while submitting ratings", life: 3000 });
                    }
                }
            });
    };
    const onSaveFeedback = (rowData: any) => {
        const token = CommonValues.GetToken();
        const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
        let data = {
            TransactionId: rowData.submissionId,
            Feedback: feedbackVal,
        };

        let config = {
            method: "post",
            url: `${process.env.REACT_APP_DataFabric_Url}/api/MTMScan/feedback`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: data,
        };

        axios
            .request(config)
            .then((response) => {
                toast.current.show({ severity: "success", detail: "Feedback Submitted successfully", life: 3000 });
                const feedback = { feedback: "", isLiked: null };
                setFeedbackLoading(false);
                setFeedBackData(feedback);
                onHideFeedbackPopup();
            })
            .catch((error: any) => {
                console.log(error);

                if (error.response != null) {
                    if (error.response.status === 401) {
                        CommonValues.Logout(navigate);
                    } else {
                        toast.current.show({ severity: "error", detail: "Unknown error while submitting feedback", life: 3000 });
                    }
                }
            });
    };

    const handleOptionSelectionChange = (event: any, value: any) => {
        setClientNameError("")
        if (value === "useExisting" && event.target.checked == true) {
            setSelectedOptionName(value);
            setIsExistingClientSelected(true);
            setIsCreateNewClientSelected(false);
            setIsCreateNewPolicySelected(true)
        } else if (value === "createNew" && event.target.checked == true) {
            setSelectedOptionName(value);
            setIsExistingClientSelected(false);
            setIsCreateNewClientSelected(true);
            setIsCreateNewPolicySelected(true)
        }
    };

    const onClickNextButton = () => {
        if (checkOnNextButtonValidation()) {
            setShowMTMSelectOptionPage(false);
        } else {
            setShowMTMSelectOptionPage(true);
        }
    }
    const onBackClick = () => {
        setShowMTMSelectOptionPage(true);
        setIsCreateNewClientSelected(true);
        setIsExistingClientSelected(false);
        setSelectedOptionName("createNew");
        setIsFileUpload(false);
        setUploadFile([]);
        setInsuredName("");
        setAddress("");
        setCity("");
        setState("");
        setZipCode("");
        setPolicyNumber("");
        setPolicyPremium("");
        setEffectiveDate("");
        setExpirationDate("");
        setCoverageLimitResponse("");
        setDriverScheduleResponse("");
        setScheduledVehicleResponse("");
        setShowUploadAgain(false);
        setClientName("");
        setClientId("");
    }
    const checkOnNextButtonValidation = () => {
        let isValid = true;
        setClientNameError("");

        if (selectedOptionName == "useExisting" && clientName.length == 0) {
            setClientNameError("Please select client name");
            isValid = false;
        } else {
            setClientNameError("");
        }
        return isValid;
    };
    const updateMTMScan = async () => {
        let token = CommonValues.GetToken();
        var config = {
            method: "post",
            url: `${process.env.REACT_APP_NOWCERTSAPI}/api/NowCert/updatemtmScan?submissionId=${submissionId}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        axios(config)
            .then((response: any) => {
                setSubmitLoading(false);
                setShowUploadAgain(true);
                toast.current.show({ severity: "success", detail: "Data updated in NowCerts.", life: 3000 });
             })
            .catch((error: any) => {
                setSubmitLoading(false);
                console.log(error);
                localStorage.removeItem("currentPage");
                if (error.response != null && error.response.status == 401) {
                    navigate("/");
                    CommonValues.Logout(navigate);
                } else {
                    toast.current.show({
                        severity: "error",
                        detail: "Error while mtm scan updating the request.",
                        life: 3000,
                    });
                }
            });
    };


    const checkValidation = () => {
        let isValid = true;
        if (insuredName == null || insuredName == undefined || insuredName == "") {
            isValid = false;
            setInsuredNameErrorMessage("Please enter insured name.");
        }
        if (address == null || address == undefined || address == "") {
            isValid = false
            setAddressErrorMessage("Please enter address.")
        }
        if (city == null || city == undefined || city == "") {
            isValid = false
            setCityErrorMessage("Please  enter city.")
        }
        if (state == null || state == undefined || state == "") {
            isValid = false
            setStateErrorMessage("Please enter state.")
        }
        if (zipCode == null || zipCode == undefined || zipCode == "") {
            isValid = false
            setZipCodeErrorMessage("Please enter zip code.")
        }
        if (policyNumber == null || policyNumber == undefined || policyNumber == "") {
            isValid = false
            setPolicyNumberErrorMessage("Please enter policy number.")
        }
        return isValid
    };

    const getCardDetails = () => {
        setCurrentBalanceLoading(true);
        var token = CommonValues.GetToken();
        var config = {
            method: "get",
            url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/current-balance`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        axios(config)
            .then((response) => {
                if (response.data.success == true) {
                    setCurrentBalance((response.data.currentBalance / 100).toFixed(2));
                }
                setCurrentBalanceLoading(false);
            })
            .catch((error) => {
                setCurrentBalanceLoading(false);
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout(navigate);
                    } else {
                        toast.current.show({
                            severity: "error",
                            detail: "Unknown error while getting current balance",
                            life: 3000,
                        });
                    }
                } else {
                    toast.current.show({
                        severity: "error",
                        detail: "Unknown error while getting current balance",
                        life: 3000,
                    });
                }
            });
    };

    return (
        <>
            <Toast ref={toast} />
            <div className="row  d-flex  align-items-end text-12 position-relative">
                {showMTMScanSelectOptionPage ?
                    <MTMScanOptionSelectPage
                        isCreateNewClientSelected={isCreateNewClientSelected}
                        isExistingClientSelected={isExistingClientSelected}
                        isCreateNewPolicySelected={isCreateNewPolicySelected}
                        handleOptionSelectionChange={handleOptionSelectionChange}
                        selectedOptionName={selectedOptionName}
                        onClickNextButton={onClickNextButton}
                        clientNameError={clientNameError}
                        clientName={clientName}
                        onClientSearchChange={onClientSearchChange}
                        searchClientList={searchClientList}
                        onClientSelect={onClientSelect}
                        search={search}
                    />
                    :
                    <>
                        
                        
                        <div className="col-12 p-0">
                            <div className="row d-flex   justify-content-end scan-search-row mx-auto">
                                <div className="col-10 d-flex  align-items-center">
                                    <span>
                                        {" "}
                                        <AtkButton label="Back" onClick={onBackClick} className="policy-back-btn w-auto  me-3" />
                                    </span>
                                    <span className=" me-3">
                                        <label>
                                            <b>Client: </b> &nbsp;
                                        </label>
                                        {selectedOptionName == "useExisting" ? clientName : "Create Client"}
                                    </span>
                                    <span>
                                        <label>
                                            <b>Policy: </b> &nbsp;
                                        </label>
                                        {"Create Policy"}
                                    </span>
                                </div>
                                <div className="col-2 text-center balance-span">
                                    <label>Current Balance</label>
                                    {currentBalanceLoading ? (
                                        <span className="balance-spinner d-flex  align-items-center justify-content-center ">
                                            <AtkLoader />
                                        </span>
                                    ) : (
                                        <span className=" d-block w-auto ">${currentBalance}</span>
                                    )}
                                    <small className="d-block w-auto text-danger mt-0">25 cent will be charged per usage</small>
                                </div>
                            </div>
                         </div>
                         
                        <div className="row  d-flex justify-content-end align-items-center text-12 position-relative mx-auto mt-2">
                            <div className="row m-3">
                                {isFileUpload ? (
                                    <div className="col">
                                        <iframe
                                            src={uploadPdfUrl}
                                            style={{
                                                overflow: "scroll",
                                                height: "100%",
                                                width: "100%",
                                            }}
                                        ></iframe>
                                    </div>
                                ) : (
                                    <>
                                        <div
                                            className="col uploadNowcertsDoc25 me-3 "
                                            onDrop={(e) => {
                                                onDropHandler(e);
                                            }}
                                            onDragOver={(e) => {
                                                e.preventDefault();
                                            }}
                                        >
                                            <div className="row w-100 ">
                                                <div className="col-12 smart-scan-dropdown pb-0">
                                                    <select className="smart-scan-dropdown" onChange={(e) => OnChangeDocumentType(e.target.value)}>
                                                        <option>Select Document Type</option>
                                                        {documentType.map((item: any) => (
                                                            <option key={item.value} value={item.value}>
                                                                {item.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="mt-2"></div>
                                                {uploadFile.length > 0 ? (
                                                    <div className="col-12">
                                                        {uploadFile.map((file: any, index: any) => (
                                                            <div className="show-pdf-icon">
                                                                <div className="scan-file-name">
                                                                    <div className="preview-icon">
                                                                        <img src={PreviewIcon} />
                                                                    </div>
                                                                    <div>
                                                                        <div title={fileNameTitle}>{fileName}</div>
                                                                        <div>
                                                                            {(parseInt(file.size) / 1000).toFixed(0)}
                                                                            KB
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {uploadComplete ? (
                                                                    <div>
                                                                        <span className="uploaded">Uploaded</span>
                                                                    </div>
                                                                ) : (
                                                                    uploadingFiles == false && (
                                                                        <div onClick={() => removeFileFromList()} className="show-cursor">
                                                                            <img src={RemoveIcon} />
                                                                        </div>
                                                                    )
                                                                )}
                                                            </div>
                                                        ))}
                                                    </div>
                                                ) : (
                                                    <>
                                                        <>
                                                            <div className="row" style={{ textAlign: "center" }}>
                                                                <div className="col-12">
                                                                    <img src={PfdIcon} alt="" width="70" />
                                                                </div>

                                                                <div className="col-12 mt-4">
                                                                    <div className="col-12">
                                                                        <span className="drag-and-drop">Drag & Drop file here</span>
                                                                    </div>
                                                                    <div className="col-12 my-2">
                                                                        <span
                                                                            style={{
                                                                                color: "black",
                                                                                fontSize: "large",
                                                                            }}
                                                                        >
                                                                            or
                                                                        </span>
                                                                    </div>
                                                                    <label className="mb-0 show-cursor">
                                                                        <span className="browse">Browse PDF</span>
                                                                        <input
                                                                            type="file"
                                                                            name="filefield"
                                                                            accept=".pdf"
                                                                            id="bulkFileUploader"
                                                                            style={{
                                                                                visibility: "hidden",
                                                                                display: "none",
                                                                            }}
                                                                            onChange={(e) => {
                                                                                onFileSelectHandler(e.target.files);
                                                                            }}
                                                                        />
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </>
                                                    </>
                                                )}
                                                <div className="col-12 text-center">
                                                    {isfileuploaded ? null : (
                                                        <>
                                                            {uploadingFiles == false &&
                                                                uploadingRetry == false &&
                                                                uploadFile.length > 0 && (
                                                                    <div
                                                                        className="d-flex justify-content-center"
                                                                        style={{
                                                                            width: "100%",
                                                                            padding: "20px",
                                                                        }}
                                                                    >
                                                                        {loading == false ? (
                                                                            <>
                                                                                <AtkButtonIconBefore
                                                                                    label="Upload"
                                                                                    className="pf-secondary-btn"
                                                                                    onClick={() => {
                                                                                        createUploadRequest();
                                                                                    }}
                                                                                />
                                                                            </>
                                                                        ) : (
                                                                            <div className="d-flex justify-content-center align-items-center p-2">
                                                                                <AtkLoader></AtkLoader>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )}
                                                        </>
                                                    )}
                                                </div>
                                                <div className="col-12 text-12 mt-3">
                                                    <AtkMessage appearance="information" messageText={"This tool works best with readable PDFs. Please note that scanned or image-based PDFs may not be processed. For scanned documents, consider converting them to a readable format using OCR tools before uploading."} />
                                                </div>
                                            </div>
                                        </div>
                                    </>)}
                                <div
                                    className="col p-2 ms-2"
                                    style={{
                                        border: "1px solid darkgrey",
                                        borderRadius: "30px",
                                        minHeight: "66vh",
                                    }}
                                >
                                    <div className="row p-0 d-flex align-items-center mx-auto smartscan-tab">
                                        <div className="col-12 d-flex align-items-center justify-content-start">
                                            <span
                                                className={activeTab === "Insured" ? "policy-name-span active show-cursor" : "policy-name-span show-cursor"}
                                                onClick={() => {
                                                    handleTabClick("Insured");
                                                }}
                                            >

                                                Insured Details
                                            </span>
                                            <span
                                                className={activeTab === "CoverageLimits" ? "policy-name-span active show-cursor" : "policy-name-span show-cursor"}
                                                onClick={() => {
                                                    handleTabClick("CoverageLimits");
                                                }}
                                            >
                                                Coverage Limits
                                            </span>
                                            <span
                                                className={activeTab === "DriverSchedule" ? "policy-name-span active show-cursor" : "policy-name-span show-cursor"}
                                                onClick={() => {
                                                    handleTabClick("DriverSchedule");
                                                }}
                                            >
                                                Driver Scheduled
                                            </span>
                                            <span
                                                className={activeTab === "ScheduleVehicles" ? "policy-name-span active show-cursor" : "policy-name-span show-cursor"}
                                                onClick={() => {
                                                    handleTabClick("ScheduleVehicles");
                                                }}
                                            >
                                                Scheduled Vehicles
                                            </span>
                                        </div>
                                    </div>
                                    {activeTab === "Insured" ? (
                                        <InsuredDetails
                                            insuredName={insuredName}
                                            address={address}
                                            city={city}
                                            state={state}
                                            zipCode={zipCode}
                                            policyNumber={policyNumber}
                                            policyPremium={policyPremium}
                                            effectiveDate={effectiveDate}
                                            expirationDate={expirationDate}
                                            onChangeEffectiveDate={onChangeEffectiveDate}
                                            onChangeExpiryDate={onChangeExpiryDate}
                                            onChangesInsuredName={onChangesInsuredName}
                                            onChangesAddress={onChangesAddress}
                                            onChangesCity={onChangesCity}
                                            onChangesState={onChangesState}
                                            onChangesZipCode={onChangesZipCode}
                                            onChangePolicyNumber={onChangePolicyNumber}
                                            onChangesPolicyPremium={onChangesPolicyPremium}
                                            insuredNameErrorMessage={insuredNameerrorMessage}
                                            addressErrorMessage={addressErrorMessage}
                                            cityErrorMessage={cityErrorMessage}
                                            stateErrorMessage={sateErrorMessage}
                                            zipCodeErrorMessage={zipCodeErrorMessage}
                                            policyNumberErrorMessage={policyNumberErrorMessage}
                                        />

                                    ) : null}
                                    {activeTab === "CoverageLimits" ? (
                                        <CoverageLimitDetails
                                            coveragelimitResponse={coveragelimitResponse}
                                            selectDocumentType={selectDocumentType}
                                        />

                                    ) : null}
                                    {activeTab === "DriverSchedule" ? (
                                        <DriverScheduleDetails
                                            driverScheduleResponse={driverScheduleResponse}
                                            fromHistoryPage={false}
                                        />

                                    ) : null}
                                    {activeTab === "ScheduleVehicles" ? (
                                        <ScheduledVehiclesDetails
                                            scheduledVehicleResponse={scheduledVehicleResponse}
                                        />
                                    ) : null}

                                    {isFileUpload ?
                                        <div className="row">
                                            <div className="col-12 d-flex align-items-center  justify-content-end my-2">
                                                {submitLoading ? <div className="d-flex justify-content-center align-items-center">
                                                    <AtkLoader />
                                                </div> : <>
                                                    {showUploadAgain ? (
                                                        <>
                                                            <AtkButtonIconBefore
                                                                label="Upload Again"
                                                                onClick={uploadAgain}
                                                                className="pf-secondary-btn w-auto me-2 "
                                                                style={{ marginTop: "7px" }}
                                                            />

                                                            <AtkButtonIconBefore
                                                                label="Feedback"
                                                                onClick={(e: any) => { onFeedbackClick(e) }}
                                                                className="pf-secondary-btn w-auto me-2 "
                                                                style={{ marginTop: "7px" }}
                                                            />
                                                        </>
                                                    ) :
                                                        <AtkButton label="Submit" onClick={onSubmit} className="pf-secondary-btn w-auto me-2" />
                                                    }
                                                </>
                                                }
                                                <OverlayPanel ref={feedbackRef} className="commission-feedback-panel feedback-panel">
                                                    <div className="row mx-auto d-flex align-items-center mb-2">
                                                        <div className="col-12 feedback-header">
                                                            <h4>Feedback</h4>
                                                        </div>
                                                        <div className={"form-group col-12 mb-2 text-12"}>
                                                            <textarea
                                                                name="feedback"
                                                                onChange={(e) => onFeedbackChange(e.target.value)}
                                                                value={feedBackData.feedback}
                                                                className={`form-control comment-text ${feedBackError.length > 0 ? " validation-div " : ""}`}
                                                                rows={4}
                                                                placeholder="Enter your feedback here"
                                                            />
                                                            <span className="text-danger  text-12 mt-1">{feedBackError}</span>
                                                        </div>
                                                        <div className="col-4 d-flex justify-content-evenly">
                                                            <i
                                                                className={`show-cursor fa fa-thumbs-up commission-feedback-icon 
                              ${feedBackData.isLiked == true ? " active" : " "}`}
                                                                aria-hidden="true"
                                                                onClick={(e) => onThumbsUpClick(feedBackData)}
                                                            ></i>
                                                            <i
                                                                className={`show-cursor fa fa-thumbs-down commission-feedback-icon 
                                ${feedBackData.isLiked == false ? " active" : " "}`}
                                                                aria-hidden="true"
                                                                onClick={(e) => onThumbDownClick(feedBackData)}
                                                            ></i>
                                                        </div>
                                                        <div className="col-4 text-end p-2 pe-0  ">
                                                            <AtkButton label="Cancel" onClick={onHideFeedbackPopup} className="pf-secondary-btn w-auto" />
                                                        </div>
                                                        <div className="col-4 text-end p-2  ">
                                                            {feedbackLoading ? (
                                                                <div className="d-flex justify-content-center align-items-center">
                                                                    <AtkLoader />
                                                                </div>
                                                            ) : (
                                                                <AtkButton
                                                                    label="Submit"
                                                                    onClick={() => onSubmitFeedbackClick(feedBackData)}
                                                                    className="pf-secondary-btn w-auto "
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </OverlayPanel>
                                            </div>
                                        </div>
                                        : null}
                                </div>
                            </div>
                        </div>
                    </>}
                    </div>
            
        </>
    )
}